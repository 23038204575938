import React from "react";
import {
    Modal,
    Stack,
    IconButton,
    MessageBar,
    MessageBarType,
} from "@fluentui/react";

interface ErrorModalProps {
    isModalOpen: boolean;
    dismissErrors: () => void;
    errors: (Error | undefined)[];
}

export const ErrorModal = (props: ErrorModalProps) => (
    <Modal isOpen={props.isModalOpen} onDismiss={props.dismissErrors} styles={{ root: { "& > .ms-Dialog-main": { minHeight: 0 } }}}>
        <Stack style={{ minWidth: "500px", height: "max-content", minHeight: 0 }}>
            <MessageBar messageBarType={MessageBarType.severeWarning}>
                {props.errors.map(e => e?.message).join(",")}
                <IconButton styles={{ root: { position: "absolute", right: 0, top: 0 } }} iconProps={{ iconName: "Cancel" }} ariaLabel="Close popup modal" onClick={props.dismissErrors} />
            </MessageBar>
        </Stack>
    </Modal>
);
