import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ActivityEntry } from "../types";
import { buildColumn, getActivityLogEntries } from "../services";
import { DetailsList, PageLayout } from "../components";
import { ConstrainMode, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react";

const ActivityEntryColumns: IColumn[] = [
    buildColumn("Date & Time", "Time", "string", { flexGrow: 1, onRender: (item: ActivityEntry) => new Date(item.Time).toLocaleString() }),
    buildColumn("Activity", "Activity", "string", { flexGrow: 1 }),
    buildColumn("Display Name", "DisplayName", "string", { flexGrow: 1 }),
    buildColumn("Login Name", "LoginName", "string", { flexGrow: 1 }),
    buildColumn("Subscription Level", "SubscriptionLevel", "string", { flexGrow: 1 }),
    buildColumn("Subscription Expiration", "SubscriptionExpiration", "string", { flexGrow: 1, onRender: (item: ActivityEntry) => new Date(item.Time).toLocaleString() }),
]

export function ActivityLogEntries() {
    const { date } = useParams()

    const [items, setItems] = useState<ActivityEntry[]>([])

    useEffect(() => {
        if (!date) {
            return
        }
        getActivityLogEntries(date)
            .then(setItems)
            .catch(err => {
                console.log("error getting activity log entries for date.", err)
            })
    }, [date])

    return <PageLayout title={`Activity for ${date}`} parent={{ url: "/Activity", title: "Activity Logs" }}>
        <DetailsList
            items={items}
            columns={ActivityEntryColumns}
            filtering={{
                columns: ActivityEntryColumns.map(x => x.key as keyof ActivityEntry)
            }}
            getColumnValue={(item: ActivityEntry, columnKey: keyof ActivityEntry) => {
                const value = item[columnKey] as string
                if (columnKey === "SubscriptionExpiration" || columnKey === "Time") {
                    return new Date(value).toLocaleString()
                }
                return value
            }}
            selectionMode={SelectionMode.none}
            compact
            constrainMode={ConstrainMode.unconstrained}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            onRenderRow={(props?, defaultRenderer?) => (
                <div style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}>{defaultRenderer!(props)}</div>
            )}
        />
    </PageLayout>
}