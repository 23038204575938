import { IColumn } from "@fluentui/react";
import { CodeKey, AppColumn, CodeRequest, CodeRequestAttachment, Code, FieldType, InstructionSet, ActivityEntry } from "../types";
import { login } from "./auth";

export const dateFormatter = new Intl.DateTimeFormat("en-US", { dateStyle: "long", timeStyle: "long" })

export function defaultColumnRender(item?: any, _?: number, column?: IColumn) {
    try {
        const fieldValue = item && column?.fieldName && (item[column.fieldName] || (item.fields && item.fields[column.fieldName]))
        switch (typeof fieldValue) {
            case "undefined": {
                return ""
            }
            case "boolean": {
                return fieldValue ? "Yes" : ""
            }
            default: {
                return `${fieldValue}`
            }
        }
    }
    catch (err) {
        return `${err}`
    }
}

type ColumnOptions = Partial<Omit<AppColumn, 'key' | 'name' | 'fieldName'>>

export const buildColumn = (displayName: string, field: string, type: FieldType, options?: ColumnOptions): AppColumn => {
    return {
        minWidth: 100,
        isResizable: true,
        ...(options ?? {}),
        key: field,
        flexGrow: 1,
        name: displayName,
        fieldName: field,
        type: type,
        onRender: options?.onRender || defaultColumnRender
    }
}

export const allCodeKeyColumns: AppColumn[] = [
    buildColumn("Date Added", "createdDate", "string", {
        onRender: (item) => {
            const codeKey = item as CodeKey;
            return codeKey && codeKey.createdDate && dateFormatter.format(new Date(codeKey.createdDate))
        }
    }),
    buildColumn("Date Modified", "modifiedDate", "string", {
        onRender: (item) => {
            const codeKey = item as CodeKey;
            return codeKey && codeKey.modifiedDate && dateFormatter.format(new Date(codeKey.modifiedDate))
        }
    }),
    buildColumn("Comments", "comments", "string"),
]

export const allColumns: AppColumn[] = [
    buildColumn("Configuration", "configuration", "string"),
    buildColumn("Capacity", "capacity", "string"),
    buildColumn("Code", "code", "string"),
    buildColumn("Abbreviation", "abbreviation", "string"),
    buildColumn("Description", "description", "string"),
    buildColumn("Definition", "definition", "string"),
    buildColumn("Material", "material", "string"),
    buildColumn("Category", "category", "string"),
    buildColumn("Cab Type", "cabType", "string"),
    buildColumn("Value", "value", "string"),
    buildColumn("Phase", "phase", "string"),
    buildColumn("Current (Amperes)", "currentAmperes", "string"),
    buildColumn("Cycles (Hz)", "cycles", "string"),
    buildColumn("KVA (Kilowatts @1pf) Range", "KVARange", "string"),
    buildColumn("Equipment Category", "equipmentCategory", "string"),
    buildColumn("Equipment Vocation", "equipmentVocation", "string"),
    buildColumn("Forward Speeds", "forwardSpeeds", "string"),
    buildColumn("Horsepower Range", "horsepowerRange", "string"),
    buildColumn("Kilowatt Range", "kilowattRange", "string"),
    buildColumn("Repair Class", "repairClass", "string"),
    buildColumn("Setting", "setting", "string"),
    buildColumn("Full Description", "fullName", "string"),
    buildColumn("Display Name", "displayName", "string"),
    buildColumn("Data Element", "dataElement", "string"),
    buildColumn("Obsolete", "isObsolete", "boolean"),
    buildColumn("Comments", "comments", "string"),
    buildColumn("Temperature", "temperature", "string"),
    buildColumn("Code(Numeric)", "codeNumeric", "string"),
    buildColumn("Code(Alpha)", "codeAlpha", "string"),
    buildColumn("SYS", "codeSys", "string"),
    buildColumn("ASY", "codeAsy", "string"),
    buildColumn("COMP", "comp", "string"),
    buildColumn("VMRS Code", "VMRSCode", "string"),
    buildColumn("Voltage", "voltage", "string"),
    buildColumn("Condition", "condition", "string"),
    buildColumn("Power Source", "powerSource", "string"),
    buildColumn("Reason for Delay", "reasonForDelay", "string"),
    buildColumn("Repair Type", "repairType", "string"),
    buildColumn("Reason for Repair", "reasonForRepair", "string"),
    buildColumn("Repair Priority Class", "repairPriorityClass", "string"),
    buildColumn("Repair Class", "repairClass", "string"),
    buildColumn("Severity Description", "severityDescription", "string"),
    buildColumn("DOT Motor Carrier Specifications: Tank Vehicles", "DOTMotorCarrierSpec", "string"),
    buildColumn("Example", "example", "string"),
    buildColumn("Body Type Description", "bodyTypeDescription", "string"),
    buildColumn("Height", "height", "string"),
    buildColumn("Metric Equivalent", "metricEquivalent", "string"),
    buildColumn("Length", "length", "string"),
    buildColumn("Setting (inches)", "settingInches", "string"),
    buildColumn("Setting (CM)", "settingCM", "string"),
    buildColumn("Transmission Type", "transmissionType", "string"),
    buildColumn("Work Accomplished", "workAccomplished", "string"),
    buildColumn("Capacity or Weight Rating", "capacityOrWeightRating", "string"),
    buildColumn("BTU/Hr", "BTUPerHour", "string"),
    buildColumn("2D_CODE", "twoDigitCode", "string"),
    buildColumn("4D_ALPHA_CODE", "fouroDigitCode", "string"),
    buildColumn("Position", "position", "string"),
]

export const columnMap: Record<string, AppColumn> = {}

allColumns.forEach(c => { columnMap[c.fieldName] = c; })

function trimJsonStringValues(jsonString: string) {
    // Regular expression to find key-value pairs in JSON
    const keyValueRegex = /"([^"]+)":\s*"([^"]*)"/g;
  
    // Function to trim trailing spaces from the value
    const trimValue = (_match: string, key: string, value: string) => `"${key}":"${value.trim()}"`;
  
    // Replace each key-value pair with its trimmed version
    let trimmedString = jsonString.replace(keyValueRegex, trimValue);

    // Remove any double spaces
    trimmedString = trimmedString.replaceAll("  ", " ")

    return trimmedString;
  }

function safeConvertToJson<T>(obj: T, property: keyof T, defaultValue?: unknown): void {
    const value = obj[property]
    if (typeof value === "string" && value.length > 0) {
        try {
            const cleanedValue = trimJsonStringValues(value)
            obj[property] = JSON.parse(cleanedValue)
        }
        catch (err) {
            obj[property] = defaultValue as T[keyof T]
            console.log("Error Parsing JSON:", property, obj, err, value)
        }
    }
}

function safeConvertFromJson<T>(obj: T, property: keyof T): void {
    const value = obj[property]
    if (typeof value !== "undefined") {
        try {
            obj[property] = JSON.stringify(value) as any
        }
        catch (err) {
            console.log("Error Parsing JSON:", property, obj, err)
        }
    }
}

function fetchVMRS<T>(input: RequestInfo | URL, init?: RequestInit | undefined): Promise<T> {
    if (!init) { init = {} }
    init.headers = { ...(init.headers || {}), Accept: "application/json" }
    return fetch(input, init)
        .then(r => {
            if (r.status === 401) {
                login()
            } else if (!r.ok) {
                return r.json().then(error => {
                    throw new Error(error.message || error.error || "An HTTP error occurred");
                });
            }
            return r.json()
        })
        .catch(err => {
            console.error("API Error:", input, init, err)
            throw err
        })
}

export async function getCodeKeys(): Promise<CodeKey[]> {
    return fetchVMRS<CodeKey[]>("/api/CodeKeys", {
        method: "GET",
        headers: { Accept: "application/json" },
    })
        .then(requests => {
            return requests.map(req => {
                safeConvertToJson(req, "fields", [])
                safeConvertToJson(req, "groups", [])
                return req
            })
        })
}

export async function getCodeKey(id: number | undefined): Promise<CodeKey> {
    return fetchVMRS<CodeKey>(`/api/CodeKeys/${id}`, { method: "GET" })
        .then(req => {
            safeConvertToJson(req, "fields")
            safeConvertToJson(req, "groups")
            return req
        })
}

export async function getCodes(codeKeyId: number): Promise<Code[]> {
    return fetchVMRS<Code[]>(`/api/CodeKeys/${codeKeyId}/Codes`)
        .then(codes => {
            return codes.map(code => {
                safeConvertToJson(code, "fields")
                return code
            })
        })
}

export async function submitNewCode(codeKeyId: number, code: Code): Promise<Code> {
    return fetchVMRS(`/api/CodeKeys/${codeKeyId}/Codes`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
            codeKeyId: codeKeyId,
            modifiedDate: code.modifiedDate,
            fields: JSON.stringify(code.fields),
        }),
    })
}

export async function submitBulkCodes(codeKeyId: number, codes: Code[]): Promise<void> {
    return fetchVMRS(`/api/CodeKeys/${codeKeyId}/bulk-codes`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(codes.map(code => ({
            codeKeyId: codeKeyId,
            modifiedDate: code.modifiedDate,
            fields: JSON.stringify(code.fields),
        })))
    });
}

export async function updateCode(code: Code): Promise<Code> {
    safeConvertFromJson(code, "fields")
    return fetchVMRS(`/api/CodeKeys/${code.codeKeyId}/Codes/${code.id}`, {
        method: "PUT",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(code),
    })
}

export async function deleteCode(code: Code): Promise<Code> {
    safeConvertFromJson(code, "fields")
    return fetchVMRS(`/api/CodeKeys/${code.codeKeyId}/Codes/${code.id}`, {
        method: "DELETE",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(code),
    })
}

export async function getCodeRequests(): Promise<CodeRequest[]> {
    return fetchVMRS<CodeRequest[]>(`/api/CodeRequests`)
        .then(requests => {
            return requests.map(req => {
                safeConvertToJson(req, "fields")
                if ("code" in req) {
                    req.codeObj = (req as any).code as Code
                    if (req.codeObj) {
                        safeConvertToJson(req.codeObj, "fields")
                    }
                    delete (req as any).code
                }
                return req
            })
        })
}

export async function submitCodeRequest(request: CodeRequest): Promise<CodeRequest> {
    safeConvertFromJson(request, "fields")
    return fetchVMRS("/api/CodeRequests", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(request),
    })
}

export async function updateCodeRequest(id: number, request: CodeRequest): Promise<CodeRequest> {
    safeConvertFromJson(request, "fields")
    return fetchVMRS(`/api/CodeRequests/${id}`, {
        method: "PUT",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(request),
    })
}

export async function getCodeRequestAttachments(codeRequestId: number): Promise<CodeRequestAttachment[]> {
    return fetchVMRS(`/api/CodeRequests/${codeRequestId}/Attachments`)
}

export async function submitCodeRequestAttachment(codeRequestId: number, file: Dropzone.DropzoneFile): Promise<CodeRequestAttachment> {
    const formData = new FormData();
    formData.append('file', file)
    return fetchVMRS(`/api/CodeRequests/${codeRequestId}/Attachments`, {
        method: "POST",
        body: formData,
    })
}

export async function deleteCodeRequestAttachments(codeRequestId: number, id: number): Promise<CodeRequestAttachment> {
    return fetchVMRS(`/api/CodeRequests/${codeRequestId}/Attachments/${id}`, { method: "DELETE" })
}

export async function getInstructionSets(): Promise<InstructionSet[]> {
    return fetchVMRS<InstructionSet[]>(`/api/InstructionSets`)
        .then(requests => {
            return requests.map(req => {
                safeConvertToJson(req, "instructions")
                return req
            })
        })
}

export async function submitInstructionSet(request: InstructionSet): Promise<InstructionSet> {
    safeConvertFromJson(request, "instructions")
    return fetchVMRS("/api/InstructionSets", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(request),
    })
}

export async function updateInstructionSet(id: number, request: InstructionSet): Promise<InstructionSet> {
    safeConvertFromJson(request, "instructions")
    return fetchVMRS(`/api/InstructionSets/${id}`, {
        method: "PUT",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(request),
    })
}

export async function getActivityLogs(): Promise<string[]> {
    return fetchVMRS<string[]>(`/api/Activity`)
}

export async function getActivityLogEntries(date: string): Promise<ActivityEntry[]> {
    return fetchVMRS<ActivityEntry[]>(`/api/Activity/${date}`)
}