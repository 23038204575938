import React from 'react'
import { DatePicker, Stack, Dropdown } from '@fluentui/react'

export interface DateRange {
    startDate: Date
    endDate: Date
    field: "createdDate" | "modifiedDate"
}

interface DateRangePickerProps {
    selectedDate: DateRange
    handleDateChange: (startDate: Date, endDate: Date, field: "createdDate" | "modifiedDate") => void
}

export const DateRangePicker = (props: DateRangePickerProps) => {
    const { selectedDate, handleDateChange } = props

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <DatePicker
                label="Start Date"
                value={selectedDate.startDate}
                styles={{ root: { width: "10rem" } }}
                onSelectDate={(date) => {
                    if (date) {
                        handleDateChange(date, selectedDate.endDate, selectedDate.field)
                    } else {
                        handleDateChange(new Date(1998, 0, 1), selectedDate.endDate, selectedDate.field)
                    }
                }}
            />
            <DatePicker
                label="End Date"
                value={selectedDate.endDate}
                styles={{ root: { width: "10rem" } }}
                onSelectDate={(date) => {
                    if (date) {
                        handleDateChange(selectedDate.startDate, date, selectedDate.field)
                    } else {
                        handleDateChange(selectedDate.startDate, new Date(), selectedDate.field)
                    }
                }}
                disabled={!selectedDate.startDate}
            />
            <Dropdown
                label="Date Field"
                selectedKey={selectedDate.field}
                onChange={(e, o) => {
                    if (o) {
                        handleDateChange(selectedDate.startDate, selectedDate.endDate, o.key as "createdDate" | "modifiedDate")
                    }
                }}
                options={[
                    { key: "createdDate", text: "Date Added" },
                    { key: "modifiedDate", text: "Date Modified" }
                ]}
            />
        </Stack>
    )
}