import { IconButton, Label, Panel, PanelType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { CSSProperties, useRef, useState } from "react";
import { submitInstructionSet, updateInstructionSet, useAuthStatus } from "../services";
import RichTextEditor, { EditorValue } from "react-rte";
import { Instruction, InstructionSet } from "../types";
import { ConfirmDialog } from "@cloudwell/simple-dialog";

interface InstructionSetPanelProps {
    instructionSet: InstructionSet;
    closePanel: () => void;
}

const areEqual = (current: Instruction[], original: Instruction[]): boolean => {
    let retVal = false
    if (current.length === original.length) {
        retVal = current.every((currentItem, position) => {
            const originalItem = original[position]
            return currentItem && originalItem && currentItem.title === originalItem.title && currentItem.description === originalItem.description
        })
    }
    return retVal
}

const canSave = (current: Partial<InstructionSet>, original: InstructionSet): boolean => {
    let retVal = false
    if (current && current.title && Array.isArray(current.instructions) && current.instructions.length > 0) {
        retVal = true
        if (original.id > 0) {
            retVal = current.title !== original.title || current.example !== original.example || !areEqual(current.instructions, original.instructions)
        }
    }
    return retVal
}

export const InstructionSetPanel = (props: InstructionSetPanelProps) => {
    const { instructionSet, closePanel } = props;
    const isEditMode = instructionSet.id > 0

    const authStatus = useAuthStatus();
    const isAdmin = true === authStatus?.user?.isAdmin
    const [saving, setSaving] = useState(false)
    const [title, setTitle] = useState(instructionSet.title)
    const [instructions, setInstructions] = useState(instructionSet.instructions)
    const [example, setExample] = useState<EditorValue>(instructionSet.example ? RichTextEditor.createValueFromString(instructionSet.example, "html") : RichTextEditor.createEmptyValue());
    const exampleText = useRef('')
    const onInstructionChanged = (instruction: Instruction, position: number) => {
        setInstructions(instructions.map((x, i) => {
            if (i === position) {
                return instruction
            }
            return x
        }))
    }

    const onInstructionAdded = () => {
        setInstructions([
            ...instructions,
            {
                title: '',
                description: ''
            }
        ])
    }

    const onInstructionRemoved = (position: number) => {
        setInstructions(instructions.filter((_, i) => i !== position))
    }

    const onSaveClicked = () => {
        setSaving(true)
        const itemToSave: InstructionSet = {
            ...instructionSet,
            title: title,
            instructions: instructions,
            example: exampleText.current
        }
        const promise = isEditMode ? updateInstructionSet(instructionSet.id, itemToSave) : submitInstructionSet(itemToSave)
        promise
            .then(() => closePanel())
            .catch(err => {
                console.log(err)
                setSaving(false)
            })
    }

    return <Panel
        isOpen={true}
        onDismiss={closePanel}
        type={PanelType.medium}
        headerText={isAdmin ? isEditMode ? "Edit Instruction Set" : "New Instruction Set" : "Instruction Set"} >
        {isAdmin
            ? <Stack tokens={{ childrenGap: 16, padding: 8 }}>
                <TextField label="Title" disabled={saving} value={title} onChange={(_, newValue) => setTitle(newValue || '')} />
                <InstructionList
                    instructions={instructions}
                    onChanged={onInstructionChanged}
                    onAdded={onInstructionAdded}
                    onRemoved={onInstructionRemoved} />
                <Stack tokens={{ childrenGap: 8 }}>
                    <Label>Examples</Label>
                    <RichTextEditor
                        value={example}
                        disabled={saving}
                        onChange={value => {
                            setExample(value)
                            if (value?.getEditorState()?.getCurrentContent()?.hasText()) {
                                exampleText.current = value.toString("html")
                            }
                            else {
                                exampleText.current = ''
                            }
                        }} />
                </Stack>
                <Stack horizontal>
                    <PrimaryButton
                        text="Save Instruction Set"
                        disabled={!canSave({ title: title, instructions: instructions, example: exampleText.current }, instructionSet)}
                        onClick={onSaveClicked} />
                </Stack>
            </Stack>
            : <Stack tokens={{ childrenGap: 16, padding: 8 }}>
                <Stack tokens={{ childrenGap: 8 }}>
                    <Label>Title</Label>
                    <div>{instructionSet.title}</div>
                </Stack>
                <InstructionList instructions={instructionSet.instructions} />
                {instructionSet.example && <Stack tokens={{ childrenGap: 8 }}>
                    <Label>Examples</Label>
                    <div dangerouslySetInnerHTML={{ __html: instructionSet.example }} />
                </Stack>}
            </Stack>}
    </Panel>
}

type InstructionListProps = {
    instructions: Instruction[]
} & ({
    onChanged?: never
    onAdded?: never
    onRemoved?: never
    disabled?: never
} | {
    onChanged: (instruction: Instruction, position: number) => void
    onAdded: () => void
    onRemoved: (position: number) => void
    disabled?: boolean
})

const InstructionList = (props: InstructionListProps) => {
    const { instructions, onAdded, onChanged, onRemoved, disabled } = props
    const canEdit = !!onChanged && !!onRemoved && !!onAdded

    return <Stack tokens={{ childrenGap: 8 }}>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Label>Instructions</Label>
            {canEdit && <IconButton
                title="Add Instruction"
                disabled={disabled}
                iconProps={{ iconName: "Add" }}
                onClick={() => onAdded()} />}
        </Stack>
        <div className="ms-Grid">
            {instructions.map((item, itemIndex) => {
                let itemProps: InstructionListItemProps = {
                    instruction: item,
                    position: itemIndex
                }
                if (canEdit) {
                    itemProps = {
                        ...itemProps,
                        onChanged: onChanged,
                        onRemoved: onRemoved,
                        disabled: disabled
                    }
                }
                return <InstructionListItem key={itemIndex} {...itemProps} />
            })}
        </div>
    </Stack>
}

const RowStyle: CSSProperties = {
    paddingTop: 8,
    lineHeight: "32px"
}

const ColStyle: CSSProperties = {
    paddingLeft: 16.5
}

type InstructionListItemProps = {
    instruction: Instruction
    position: number
} & ({
    onChanged?: never
    onRemoved?: never
    disabled?: never
} | {
    onChanged: (value: Instruction, position: number) => void
    onRemoved: (position: number) => void
    disabled?: boolean
})

const InstructionListItem = (props: InstructionListItemProps) => {
    const { instruction, onChanged, onRemoved, position, disabled } = props
    const canEdit = !!onChanged && !!onRemoved

    const [title, setTitle] = useState(instruction.title)
    const [description, setDescription] = useState(instruction.description)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const isDirty = title !== instruction.title || description !== instruction.description

    return <div className="ms-Grid-row" style={position > 0 ? RowStyle : undefined} dir="ltr" >
        {canEdit && isEditMode
            ? <>
                <div className="ms-Grid-col ms-sm3">
                    <TextField value={title} onChange={(_, newValue) => setTitle(newValue || '')} />
                </div>
                <div className="ms-Grid-col ms-sm7">
                    <TextField value={description} onChange={(_, newValue) => setDescription(newValue || '')} />
                </div>
                <div className="ms-Grid-col ms-sm2">
                    <Stack horizontal horizontalAlign="end">
                        <IconButton
                            title="Save"
                            disabled={disabled || !isDirty}
                            iconProps={{ iconName: "Save" }}
                            onClick={() => {
                                setIsEditMode(false)
                                onChanged({ title: title, description: description }, position)
                            }} />
                        <IconButton
                            title="Cancel"
                            disabled={disabled}
                            iconProps={{ iconName: "Cancel" }}
                            onClick={() => {
                                setTitle(instruction.title)
                                setDescription(instruction.description)
                                setIsEditMode(false)
                            }} />
                    </Stack>
                </div>
            </>
            : <>
                <div className="ms-Grid-col ms-sm3" style={canEdit ? ColStyle : { paddingLeft: 0 }}>{instruction.title}</div>
                <div className={`ms-Grid-col ${canEdit ? "ms-sm7" : "ms-sm9"}`} style={canEdit ? ColStyle : undefined}>{instruction.description}</div>
                {canEdit && <div className="ms-Grid-col ms-sm2">
                    <Stack horizontal horizontalAlign="end">
                        <IconButton
                            title="Delete"
                            disabled={disabled}
                            iconProps={{ iconName: "Delete" }}
                            onClick={() => setShowConfirmDelete(true)} />
                        <IconButton
                            title="Edit"
                            disabled={disabled}
                            iconProps={{ iconName: "Edit" }}
                            onClick={() => setIsEditMode(true)} />
                    </Stack>
                    {showConfirmDelete && <ConfirmDialog
                        title="Confirm Delete"
                        message={`Are you sure you want to delete ${title || ("the item at position " + (position + 1))}?`}
                        onClose={confirmed => {
                            if (confirmed) {
                                onRemoved(position)
                            }
                            setShowConfirmDelete(false)
                        }} />}
                </div>}
            </>}

    </div>
}