import { PrimaryButton, SelectionMode } from "@fluentui/react";
import { useState, useRef, useEffect } from "react";
import { DetailsList, PageLayout, RefreshButton, SearchOptions } from "../components";
import { buildColumn, getInstructionSets, useAuthStatus } from "../services";
import { InstructionSet } from "../types";
import { InstructionSetPanel } from ".";

const getInstructionSet = (instructionSet?: Partial<InstructionSet>): InstructionSet => {
    const now = new Date()
    return {
        id: instructionSet?.id || 0,
        createdBy: instructionSet?.createdBy || '',
        createdDate: instructionSet?.createdDate || now,
        modifiedBy: instructionSet?.modifiedBy || '',
        modifiedDate: instructionSet?.modifiedDate || now,
        title: instructionSet?.title || '',
        example: instructionSet?.example || '',
        instructions: instructionSet?.instructions || []
    }
}

export const InstructionSets = () => {
    const authStatus = useAuthStatus()
    const [instructionSets, setInstructionSets] = useState<InstructionSet[]>([])
    const [instructionSet, setInstructionSet] = useState<InstructionSet>();
    const searchText = useRef("");

    const [filteredInstructionSets, setFilteredInstructionSets] = useState<InstructionSet[]>(instructionSets.slice());

    const columns = getColumns()

    const addInstructionSetButton = authStatus?.user?.isAdmin && (
        <PrimaryButton
            text="Add Instruction Set"
            onClick={() => {
                setInstructionSet(getInstructionSet())
            }}
        />
    )

    const searchOptions: SearchOptions = {
        placeholder: "Search Instruction Sets",
        value: searchText.current,
        onChange: (value) => {
            searchText.current = value || "";
            setFilteredInstructionSets(getFilteredItems(instructionSets, searchText.current));
        }
    }

    const refresh = () => {
        getInstructionSets()
            .then(r => {
                setInstructionSets(r)
                setFilteredInstructionSets(getFilteredItems(r, searchText.current))
            })
            .catch(err => console.log(err));
    }

    useEffect(() => refresh(), [])

    return (
        <PageLayout title="VMRS Instruction Sets" searchOptions={searchOptions} searchProps={{ style: { minWidth: "10rem" } }} action={addInstructionSetButton} actionRight={<RefreshButton onClick={refresh} />}>
            <DetailsList
                items={filteredInstructionSets}
                columns={columns}
                compact
                styles={{
                    contentWrapper: {
                        ".ms-DetailsRow-cell": {
                            borderLeft: "1px solid rgb(237, 235, 233)",
                            borderRight: "1px solid rgb(237, 235, 233)"
                        }
                    }
                }}
                selectionMode={SelectionMode.none}
                onRenderRow={(props?, defaultRenderer?) => (
                    <div
                        style={{ cursor: "pointer", borderBottom: "1px solid rgb(237, 235, 233)" }}
                        onClick={() => setInstructionSet(props?.item)} >
                        {defaultRenderer!(props)}
                    </div>
                )}
            />
            {instructionSet && (
                <InstructionSetPanel
                    instructionSet={instructionSet}
                    closePanel={() => {
                        setInstructionSet(undefined)
                        refresh()
                    }} />
            )}
        </PageLayout>
    );
};

const getFilteredItems = (instructionSets: InstructionSet[], searchText: string): InstructionSet[] => {
    let filteredItems = instructionSets

    if (searchText && searchText.length > 0) {
        const normalizedSearchText = searchText.toLocaleUpperCase()
        filteredItems = instructionSets.filter(instructionSet => {
            return instructionSet.title?.toLocaleUpperCase()?.includes(normalizedSearchText) ||
                instructionSet.example?.toLocaleUpperCase()?.includes(normalizedSearchText) ||
                instructionSet.instructions.some(instruction => {
                    return instruction.title?.toLocaleUpperCase()?.includes(normalizedSearchText) ||
                        instruction.description?.toLocaleUpperCase()?.includes(normalizedSearchText)
                })
        })
    }

    return filteredItems
}

const getColumns = () => {
    return [buildColumn("Instruction Set", "title", "string")];
};