import { useState, useEffect } from "react";
import { CodeRequestPanel } from ".";
import { buildColumn, columnMap, defaultColumnRender, getCodeRequests } from "../services";
import { CodeRequest } from "../types";
import { SelectionMode, DetailsListLayoutMode, ConstrainMode, PrimaryButton, ProgressIndicator } from "@fluentui/react";
import { PageLayout, RefreshButton, DetailsList } from "../components";

const codeRequestColumns = [
    buildColumn("ID", "id", "string"),
    buildColumn("Requester", "createdBy", "string"),
    buildColumn("Code Key", "codeKeyId", "string", {
        onRender: (item: CodeRequest) => {
            return item && item.codeKey && item.codeKey.displayName
        }
    }),
    buildColumn("Code ID", "codeId", "string", {
        onRender: (item: CodeRequest) => {
            return item && item.codeObj ? <RecordFields record={item.codeObj} /> : "N/A"
        }
    }),
    buildColumn("Fields", "fields", "string", {
        onRender: (item: CodeRequest) => <RecordFields record={item} />
    }),
    buildColumn("Status", "status", "string"),
    buildColumn("Comments", "comments", "string"),
];

export const CodeRequests = () => {
    const [codeRequests, setCodeRequests] = useState<CodeRequest[]>([]);
    const [editProps, setEditProps] = useState<CodeRequest>();
    const [showNewRequest, setShowNewReqest] = useState(false);
    const [loading, setLoading] = useState(false);

    const refresh = () => {
        setLoading(true)
        getCodeRequests()
            .then(ck => {
                setLoading(false)
                setCodeRequests(ck)
            })
    }

    useEffect(() => refresh(), []);

    const newRequestButton = <PrimaryButton onClick={() => setShowNewReqest(true)}>Create New Code Request</PrimaryButton>

    return <PageLayout title="Request New VMRS Codes" action={newRequestButton} actionRight={<RefreshButton onClick={refresh} />}>
        {loading && <ProgressIndicator label="Loading..." />}
        <DetailsList
            items={codeRequests}
            columns={codeRequestColumns}
            compact
            styles={{
                contentWrapper: {
                    ".ms-DetailsRow-cell": {
                        borderLeft: "1px solid rgb(237, 235, 233)",
                        borderRight: "1px solid rgb(237, 235, 233)"
                    }
                }
            }}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            onRenderRow={(props?, defaultRenderer?) => (
                <div
                    style={{ cursor: "pointer", borderBottom: "1px solid rgb(237, 235, 233)" }}
                    onClick={() => {
                        setEditProps(props?.item);
                    }} >{defaultRenderer!(props)}</div>
            )} />
        {(showNewRequest || editProps) && <CodeRequestPanel
            codeRequest={editProps}
            dismissPanel={success => {
                if (success) {
                    refresh()
                }
                setShowNewReqest(false)
                setEditProps(undefined)
            }} />}
    </PageLayout>
}

interface RecordFieldsProps {
    record: any
}

function RecordFields(props: RecordFieldsProps) {
    const { record } = props
    return record && record.fields && <table style={{ borderSpacing: 0 }}>
        <tbody>
            {Object.keys(record.fields).map(fieldName => {
                const field = columnMap[fieldName]
                const fieldDisplayName = field?.name
                return <tr key={fieldName}>
                    <td style={{ paddingRight: "15px" }}><strong>{fieldDisplayName || fieldName}</strong></td>
                    <td>{defaultColumnRender(record, undefined, field)}</td>
                </tr>
            })}
        </tbody>
    </table>
}