import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { DetailsList, PageLayout } from "../components"
import { ConstrainMode, DetailsListLayoutMode, SelectionMode } from "@fluentui/react"
import { getActivityLogs } from "../services";

export function ActivityLogs() {
    const navigate = useNavigate();

    const [dates, setDates] = useState<string[]>([])

    useEffect(() => {
        getActivityLogs()
            .then(setDates)
            .catch(err => {
                console.log("error getting activity log dates.", err)
            })
    }, [])

    return <PageLayout title="Activity Logs">
        <DetailsList
            items={dates}
            selectionMode={SelectionMode.none}
            columns={[
                {
                    key: "date",
                    name: "Date",
                    minWidth: 100,
                    onRender: (item) => item,
                    flexGrow: 2
                }
            ]}
            compact
            constrainMode={ConstrainMode.unconstrained}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            onRenderRow={(props?, defaultRenderer?) => (
                <div
                    style={{ cursor: "pointer", borderBottom: "1px solid rgb(237, 235, 233)" }}
                    onClick={() => {
                        navigate(`/Activity/${props?.item}`);
                    }}
                >{defaultRenderer!(props)}</div>
            )}
        />
    </PageLayout>
}