import { DefaultButton, Stack } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../components/PageLayout";

export function Landing() {
    const navigate = useNavigate();
    return (
        <PageLayout title="VMRS Service Portal">
            <Stack tokens={{ childrenGap: 16 }} horizontal horizontalAlign="center">
                <DefaultButton
                    className="dashboard-button"
                    onClick={() => navigate("/CodeRequests")}
                    title="VMRS Code Requests"
                    text="VMRS Code Requests" />
                <DefaultButton
                    className="dashboard-button"
                    onClick={() => navigate("/CodeKeys")}
                    title="Access VMRS Codes"
                    text="Access VMRS Codes" />
                <DefaultButton
                    className="dashboard-button"
                    onClick={() => navigate("/InstructionSets")}
                    title="VMRS Instruction Sets"
                    text="VMRS Instruction Sets" />
            </Stack>
            <Stack tokens={{ childrenGap: 16 }} horizontal horizontalAlign="center">
                <DefaultButton
                    className="dashboard-button"
                    href="/files/HB_COMPLETE_v20.pdf"
                    title="VMRS Implementation Handbook"
                    text="VMRS Implementation Handbook"
                />
                <DefaultButton
                    className="dashboard-button"
                    data-interception="off"
                    target="_blank"
                    href="https://tmc.trucking.org/sites/default/files/VMRS_INTRO.pdf"
                    title="FAQ"
                    text="FAQ"
                />
                <DefaultButton
                    className="dashboard-button"
                    href="mailto:tmc@trucking.org?subject=Question%20from%20VMRS%20Portal"
                    title="Questions?"
                    text="Questions?"
                />
            </Stack>
            <Stack tokens={{ childrenGap: 16 }} horizontal horizontalAlign="center">
                <DefaultButton
                    className="dashboard-button"
                    data-interception="off"
                    target="_blank"
                    href="https://www.atabusinesssolutions.com/Shopping/?Category=Technology%20&%20Maintenance&SubCategory=ATA%27s%20TMC%20Vehicle%20Maintenance%20Reporting%20Standards"
                    title="Purchase other VMRS Products"
                    text="Purchase other VMRS Products"
                />
                <DefaultButton
                    className="dashboard-button"
                    data-interception="off"
                    target="_blank"
                    href="https://tmc.trucking.org/VMRS-Overview"
                    title="VMRS Home Page"
                    text="VMRS Home Page"
                />
                <DefaultButton
                    className="dashboard-button"
                    data-interception="off"
                    target="_blank"
                    href="https://tmc.trucking.org/blog/current-list-vmrs-license-holders"
                    title="Current Licensees"
                    text="Current Licensees"
                />
            </Stack>
        </PageLayout>
    );
}