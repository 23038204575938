import { MessageBar, MessageBarType, Spinner, Stack } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import "./app.css";
import { Layout, RequireAdmin, RequireLogin } from "./components";
import { CodeKeyDetails, CodeKeys, Landing, CodeRequests, InstructionSets, ActivityLogs, ActivityLogEntries } from "./pages";
import { AuthStatus, getAuthStatus, AuthStatusRefreshContext, AuthStatusContext, } from "./services";

type AppState = "init" | "loading" | "ready";

export function App() {
    const [error, setError] = useState<string | undefined>();
    const [status, setStatus] = useState<AuthStatus | null>(null);
    const [state, setState] = useState<AppState>("init");

    const loadStatus = useCallback(() => {
        getAuthStatus()
            .then(status => setStatus(status))
            .catch(err => setError(err.toString()))
            .finally(() => setState("ready"));
    }, []);

    useEffect(() => {
        loadStatus();
    }, [loadStatus]);

    return <>
        {state === "loading" && (<Spinner label="Getting user information..." labelPosition="bottom" />)}
        {error && (<MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>)}
        {state === "ready" && !error && <AuthStatusRefreshContext.Provider value={loadStatus}>
            <AuthStatusContext.Provider value={status}>
                <Routes>
                    <Route element={<Layout />}>
                        <Route element={<RequireLogin />}>
                            <Route path="/" element={<Landing />} />
                            <Route path="/CodeRequests" element={<CodeRequests />} />
                            <Route path="/CodeKeys/:id" element={<CodeKeyDetails />} />
                            <Route path="/CodeKeys" element={<CodeKeys />} />
                            <Route path="/InstructionSets" element={<InstructionSets />}
                            />
                        </Route>
                        <Route element={<RequireAdmin />}>
                            <Route path="/Activity" element={<ActivityLogs />} />
                            <Route path="/Activity/:date" element={<ActivityLogEntries />} />
                        </Route>
                        <Route
                            path="/AuthFailed"
                            element={<MessageBar messageBarType={MessageBarType.error} >
                                <Stack tokens={{ childrenGap: 4 }}>
                                    <span>Authentication Failed</span>
                                    <Link className="ms-3" to="/">Try Again</Link>
                                    <span>Please use the Contact Us link if the problem continues.</span>
                                </Stack>
                            </MessageBar>} />
                        <Route
                            path="/*"
                            element={<MessageBar messageBarType={MessageBarType.error} >
                                <span>Page Not Found</span>
                                <Link className="ms-3" to="/">Home</Link>
                            </MessageBar>} />
                    </Route>
                </Routes>
            </AuthStatusContext.Provider>
        </AuthStatusRefreshContext.Provider>}
    </>
}