import { ConstrainMode, DetailsListLayoutMode, IColumn, SelectionMode, Toggle, ProgressIndicator } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageLayout, SearchOptions, DetailsList } from "../components";
import { buildColumn, dateFormatter, getCodeKeys } from "../services";
import { AppColumn, CodeKey } from "../types";

const getFilteredItems = (codeKeys: CodeKey[], searchText: string, excludeObsolete: boolean): CodeKey[] => {
    let filteredItems = codeKeys;

    if (excludeObsolete) {
        filteredItems = codeKeys.filter((i) => !i.isObsolete);
    }

    if (searchText && searchText.length > 0) {
        const normalizedSearchText = searchText.toLocaleUpperCase();
        filteredItems = codeKeys.filter((i) => {
            return (
                i.displayName?.toLocaleUpperCase()?.includes(normalizedSearchText)
            );
        });
    }

    return filteredItems;
};

const codeKeyColumns: AppColumn[] = [
    buildColumn(
        "Code Key",
        "displayName",
        "string",
        {
            maxWidth: 500,
            onRender: (item) => {
                const codeKey = item as CodeKey;
                return codeKey && codeKey.displayName;
            }
        }
    ),
    buildColumn(
        "Obsolete",
        "isObsolete",
        "boolean",
        {
            maxWidth: 100,
            onRender: (item) => {
                const codeKey = item as CodeKey;
                return codeKey && codeKey.isObsolete && <span>Yes</span>;
            }
        }
    ),
    buildColumn(
        "Date Added",
        "createdDate",
        "string",
        {
            minWidth: 190,
            maxWidth: 190,
            isResizable: false,
            onRender: (item) => {
                const codeKey = item as CodeKey;
                return codeKey && codeKey.createdDate && <span>{dateFormatter.format(new Date(codeKey.createdDate))}</span>;
            }
        }),
    buildColumn(
        "Date Modified",
        "modifiedDate",
        "string",
        {
            minWidth: 190,
            maxWidth: 190,
            isResizable: false,
            onRender: (item) => {
                const codeKey = item as CodeKey;
                return codeKey && codeKey.modifiedDate && <span>{dateFormatter.format(new Date(codeKey.modifiedDate))}</span>;
            }
        }),
]

export function CodeKeys() {
    const allCodeKeys = useRef<CodeKey[]>([]);
    const [filteredCodeKeys, setFilteredCodeKeys] = useState<CodeKey[]>([]);
    const [loading, setLoading] = useState(false);
    const searchText = useRef("");
    const excludeObsolete = useRef(true);
    const navigate = useNavigate();

    const [sortColumn, setSortColumn] = useState<string | undefined>();
    const [sortAscending, setSortAscending] = useState(true);

    const [columns, setColumns] = useState<AppColumn[]>(codeKeyColumns.filter((c) => !excludeObsolete.current || c.key !== "isObsolete"));

    const onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        if (column) {
            const newSortAscending = sortColumn === column.fieldName ? !sortAscending : true;
            setSortColumn(column.fieldName);
            setSortAscending(newSortAscending);

            const newItems = [...filteredCodeKeys].sort((a, b) => {
                const valueA = a[column.fieldName as keyof CodeKey];
                const valueB = b[column.fieldName as keyof CodeKey];
                if (valueA < valueB) {
                    return newSortAscending ? -1 : 1;
                }
                if (valueA > valueB) {
                    return newSortAscending ? 1 : -1;
                }
                return 0;
            });

            setFilteredCodeKeys(newItems);

            // Update the columns array to mark the sorted column
            const updatedColumns = columns.map((col) => {
                if (col.fieldName === column.fieldName) {
                    return {
                        ...col,
                        isSorted: true,
                        isSortedDescending: !newSortAscending,
                    };
                }
                return {
                    ...col,
                    isSorted: false,
                    isSortedDescending: false,
                };
            });

            setColumns(updatedColumns);
        }
    };

    useEffect(() => {
        setLoading(true);
        getCodeKeys()
            .then((codeKeys) => {
                allCodeKeys.current = codeKeys;
                setFilteredCodeKeys(getFilteredItems(allCodeKeys.current, searchText.current, excludeObsolete.current));
            })
            .catch((error) => {
                // Handle error here (e.g., display an error message or retry)
                console.error("Error getting code keys:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const searchOptions: SearchOptions = {
        placeholder: "Search Code Keys",
        value: searchText.current,
        onChange: (value) => {
            searchText.current = value || "";
            setFilteredCodeKeys(getFilteredItems(allCodeKeys.current, searchText.current, excludeObsolete.current));
        },
    };

    const obsoleteToggle = (
        <Toggle
            label="Exclude Obsolete"
            inlineLabel
            onText=" "
            offText=" "
            checked={excludeObsolete.current}
            onChange={(_event, checked) => {
                excludeObsolete.current = Boolean(checked);

                setColumns(codeKeyColumns.filter((c) => !excludeObsolete.current || c.key !== "isObsolete"));

                setFilteredCodeKeys(getFilteredItems(allCodeKeys.current, searchText.current, excludeObsolete.current));
            }}
        />
    );

    return (
        <PageLayout title="Code Keys" actionRight={obsoleteToggle} searchOptions={searchOptions}>
            {loading && <ProgressIndicator label="Loading..." />}
            {!loading && <DetailsList
                items={filteredCodeKeys}
                selectionMode={SelectionMode.none}
                columns={columns}
                compact
                onColumnHeaderClick={onColumnHeaderClick}
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={excludeObsolete.current ? DetailsListLayoutMode.justified : DetailsListLayoutMode.fixedColumns}
                onRenderRow={(props?, defaultRenderer?) => (
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            navigate(`/CodeKeys/${props?.item?.id}`);
                        }}
                    >
                        {defaultRenderer!(props)}
                    </div>
                )}
            />}
        </PageLayout>
    );
}