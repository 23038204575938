import { createContext, useContext } from "react"

export type SubscriptionLevel = "None" | "Corporate" | "Development" | "Distribution" | "Electronic Catalog"

export interface SubscriptionInfo {
    level: SubscriptionLevel
    expiration: Date
}

export interface UserInfo {
    loginName: string
    displayName: string
    isAdmin: boolean
    subscription?: SubscriptionInfo
}

export interface AuthStatus {
    user?: UserInfo
    isAuthenticated: boolean
}

export const AuthStatusContext = createContext<AuthStatus | null>(null)

export const useAuthStatus = () => useContext(AuthStatusContext)

export const AuthStatusRefreshContext = createContext<CallableFunction>(() => { })

export const useAuthStatusRefresh = () => useContext(AuthStatusRefreshContext)

export async function getAuthStatus(): Promise<AuthStatus> {
    return fetch("/sso/status", { method: "GET", headers: { "Accept": "application/json" } })
        .then(r => r.json())
        .then(d => {
            if (d && d.user && d.user.subscription) {
                if (d.user.subscription.level === "ElectronicCatalog") {
                    d.user.subscription.level = "Electronic Catalog"
                }
                if (typeof d.user.subscription.expiration === "string") {
                    d.user.subscription.expiration = new Date(d.user.subscription.expiration)
                }
            }
            return d
        })
}

export function login(): void {
    window.location.assign("/sso/login")
}

export function logout(): void {
    window.location.assign("/sso/logout")
}