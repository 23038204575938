import React, { useState, useEffect } from "react";
import { ErrorModal } from "../components/ErrorModal";
import { PrimaryButton, Stack, TextField, IconButton, List, Spinner, SpinnerSize, Label, Dropdown, IDropdownOption, Panel, PanelType, Toggle } from "@fluentui/react";
import { AppColumn, Code, CodeKey, CodeRequest, CodeRequestAttachment, CodeRequestStatus } from "../types";
import { DropzoneComponent } from "react-dropzone-component";
import { allColumns, deleteCodeRequestAttachments, getCodeKeys, getCodeRequestAttachments, submitCodeRequest, submitCodeRequestAttachment, updateCodeRequest, useAuthStatus } from "../services";

const statusOptions: IDropdownOption[] = [
    { key: "Pending", text: "Pending" },
    { key: "Rejected", text: "Rejected" },
    { key: "Approved", text: "Approved" },
];

function getCodeRequest(codeRequest?: Partial<CodeRequest>): CodeRequest {
    const now = new Date()
    return {
        id: codeRequest?.id || 0,
        codeKeyId: codeRequest?.codeKeyId || 0,
        fields: codeRequest?.fields || {},
        status: codeRequest?.status || CodeRequestStatus.Pending,
        comments: codeRequest?.comments || '',
        codeId: codeRequest?.codeId,
        codeKey: codeRequest?.codeKey,
        createdBy: codeRequest?.createdBy || '',
        createdDate: codeRequest?.createdDate || now,
        modifiedBy: codeRequest?.modifiedBy || '',
        modifiedDate: codeRequest?.modifiedDate || now
    }
}

function calculateDirtyState(originalRequest: CodeRequest | undefined, currentRequest: CodeRequest): boolean {
    const originalComments = originalRequest?.comments || ''
    const originalStatus = originalRequest?.status || CodeRequestStatus.Pending
    const originalFields = originalRequest?.fields || {}

    return originalComments !== currentRequest.comments || originalStatus !== currentRequest.status || Object.keys(currentRequest.fields).some(key => {
        const originalValue = originalFields[key]
        const currentValue = currentRequest.fields[key]
        if (typeof originalValue !== "undefined") {
            return originalValue !== currentValue
        }
        else {
            return Boolean(currentValue)
        }
    })
}

interface EditRequestAtt extends CodeRequestAttachment {
    action: "original" | "new" | "delete"
    file?: Dropzone.DropzoneFile
}

interface ExistingRequest {
    codeRequest: CodeRequest | undefined
    code?: never
    codeKey?: never
}

interface NewRequestForExistingCode {
    codeRequest?: never
    code: Code
    codeKey?: never
}

interface NewRequestForSpecifiedCodeKey {
    codeRequest?: never
    code?: never
    codeKey?: CodeKey
}

interface NewRequestForAnyCodeKey {
    codeRequest?: never
    code?: never
    codeKey?: never
}

export type CodeRequestPanelProps = {
    dismissPanel: (success: boolean) => void
} & (ExistingRequest | NewRequestForExistingCode | NewRequestForSpecifiedCodeKey | NewRequestForAnyCodeKey)

export const CodeRequestPanel = (props: CodeRequestPanelProps) => {
    const { dismissPanel, codeRequest: originalRequest, code, codeKey } = props
    const isEditExistingCodeRequest = !!originalRequest, isEditCode = !!code, isAddForCodeKey = !!codeKey

    const [codeRequest, setCodeRequest] = useState(getCodeRequest(originalRequest))
    const [attachments, setAttachments] = useState<EditRequestAtt[]>([])
    const [codeKeyOptions, setCodeKeyOptions] = useState<IDropdownOption<CodeKey>[]>([])
    const [codeKeyOption, setCodeKeyOption] = useState<IDropdownOption<CodeKey>>()
    const [fields, setFields] = useState<AppColumn[]>([])
    const [errors, setErrors] = useState<(Error | undefined)[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const authStatus = useAuthStatus()

    useEffect(() => {
        getCodeKeys()
            .then(codeKeys => {
                setCodeKeyOptions(codeKeys.filter(codeKey => !codeKey.isObsolete).map(codeKey => {
                    return {
                        key: codeKey.id,
                        text: codeKey.displayName,
                        data: codeKey
                    }
                }))
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        const codeKeyId = originalRequest?.codeKeyId || code?.codeKeyId || codeKey?.id
        if (codeKeyOptions && codeKeyId) {
            setCodeKeyOption(codeKeyOptions.find(codeKey => codeKeyId === codeKey.data?.id))
        }
    }, [originalRequest, code, codeKey, codeKeyOptions])

    useEffect(() => {
        const ck = codeKeyOption?.data
        if (ck) {
            const fieldSet = allColumns.filter(f => f.fieldName === "isObsolete" || ck.fields.includes(f.fieldName))
            setFields(fieldSet.length > 0 ? fieldSet : []);
        }
    }, [codeKeyOption])

    useEffect(() => {
        if (originalRequest?.id) {
            getCodeRequestAttachments(originalRequest?.id)
                .then(async att => {
                    const newArr: EditRequestAtt[] = att.map((a: CodeRequestAttachment) => {
                        return { ...a, action: "original" };
                    });
                    setAttachments(newArr);
                });
        }
    }, [originalRequest?.id]);

    useEffect(() => {
        if (code?.fields) {
            setCodeRequest(cr => ({
                ...cr,
                fields: code.fields
            }))
        }
    }, [code?.fields])

    useEffect(() => {
        setIsDirty(calculateDirtyState(originalRequest, codeRequest))
    }, [originalRequest, codeRequest])

    const dismissErrors = () => {
        setIsLoading(false);
        setErrors([]);
        dismissPanel(true);
    };

    const _deleteAttachment = (item: EditRequestAtt) => {
        let newAttachments = attachments.slice()
        if (item.action === "original") {
            item.action = "delete"
        }
        if (item.action === "new") {
            newAttachments = attachments.filter(a => a !== item)
        }
        setAttachments(newAttachments)
        setIsDirty(newAttachments.some(a => a.action === "new" || a.action === "delete"))
    };

    const _onRenderAttachmentItem = (item?: EditRequestAtt) => {
        return item && item.action !== "delete" && <Stack horizontal horizontalAlign="space-between" verticalAlign="center" >
            <Stack.Item>{item.name}</Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 4 }}>
                {item.id > 0 && <IconButton
                    onClick={() => window.open(`/api/CodeRequests/${item.codeRequestId}/Attachments/${item.id}`)}
                    iconProps={{ iconName: "Download" }} />}
                <IconButton
                    name={item.name || ""}
                    onClick={() => _deleteAttachment(item)}
                    iconProps={{ iconName: "Delete" }} />
            </Stack>
        </Stack>
    }

    const _onDropzoneFileAdded = (file: Dropzone.DropzoneFile) => {
        const now = new Date()
        setAttachments(prev => [
            ...prev,
            {
                id: 0,
                codeRequestId: originalRequest?.id || 0,
                name: file.name,
                file: file,
                action: "new",
                createdBy: '',
                createdDate: now,
                modifiedBy: '',
                modifiedDate: now
            },
        ]);
        setIsDirty(true);
    };

    const onSubmit = () => {
        if (codeRequest) {
            setIsLoading(true);
            if (codeRequest.codeKeyId < 1) {
                const codeKeyId = originalRequest?.codeKeyId || code?.codeKeyId || codeKey?.id || codeKeyOption?.data?.id
                if (typeof codeKeyId === "number" && !Number.isNaN(codeKeyId)) {
                    codeRequest.codeKeyId = codeKeyId
                }
            }
            const promise = originalRequest?.id ? updateCodeRequest(originalRequest?.id, codeRequest) : submitCodeRequest(codeRequest)
            promise
                .then(r => {
                    if (attachments.length > 0) {
                        const attachmentPromises = attachments.map(att => {
                            if (att.action === "delete") {
                                return new Promise<Error | undefined>(resolve => {
                                    deleteCodeRequestAttachments(r.id, att.id)
                                        .then(() => resolve(undefined))
                                        .catch(err => resolve(err))
                                })
                            }
                            else if (att.action === "new") {
                                return new Promise<Error | undefined>(resolve => {
                                    submitCodeRequestAttachment(att.codeRequestId, att.file!)
                                        .then(() => resolve(undefined))
                                        .catch(err => resolve(err));
                                });
                            }
                            return Promise.resolve(undefined);
                        });
                        Promise.all(attachmentPromises).then(responses => {
                            const errs = responses.filter(r => r);
                            if (errs.length > 0) {
                                console.log(errs);
                                setErrors(errs);
                            }
                            else {
                                dismissPanel(true);
                            }
                        });
                    }
                    else {
                        dismissPanel(true);
                    }
                });
        }
    };

    const onStatusChanged = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option?.key === CodeRequestStatus.Pending) {
            setCodeRequest(codeRequest && {
                ...codeRequest,
                status: CodeRequestStatus.Pending,
            });
        }
        if (option?.key === CodeRequestStatus.Rejected) {
            setCodeRequest({
                ...codeRequest,
                status: CodeRequestStatus.Rejected,
            });
        }
        if (option?.key === CodeRequestStatus.Approved) {
            setCodeRequest({
                ...codeRequest,
                status: CodeRequestStatus.Approved,
            });
        }
    };

    return <Panel headerText={isEditExistingCodeRequest ? "Edit Code Request" : "New Code Request"} isOpen={true} onDismiss={() => dismissPanel(true)} type={PanelType.medium} >
        <Stack tokens={{ childrenGap: 8 }}>
            {errors.length > 0 && <ErrorModal isModalOpen={true} dismissErrors={dismissErrors} errors={errors} />}
            <Dropdown
                placeholder="Select a Code Key"
                label="Code Key"
                options={codeKeyOptions}
                disabled={isEditExistingCodeRequest || isEditCode || isAddForCodeKey}
                selectedKey={codeKeyOption?.key}
                onChange={(_, option) => setCodeKeyOption(option)} />
            <Dropdown
                label="Status"
                options={statusOptions}
                disabled={!authStatus?.user?.isAdmin}
                selectedKey={codeRequest.status}
                onChange={onStatusChanged} />
            {fields.length > 0 && fields.map((field, i) => {
                switch (field.type) {
                    case "boolean": {
                        const isChecked = codeRequest.fields[field.fieldName] as boolean
                        return <Toggle
                            key={`${field}${i}`}
                            label={field.name}
                            defaultChecked={isChecked}
                            onChanged={(checked) => {
                                const newFields = { ...codeRequest.fields }
                                newFields[field.fieldName] = checked
                                setCodeRequest({ ...codeRequest, fields: newFields })
                            }} />
                    }
                    case "string": {
                        const stringValue = codeRequest.fields[field.fieldName] as string;
                        return <TextField
                            key={`${field}${i}`}
                            label={field.name}
                            defaultValue={stringValue}
                            onChange={(e, fieldEdit) => {
                                const newFields = { ...codeRequest.fields }
                                newFields[field.fieldName] = fieldEdit || ''
                                setCodeRequest({ ...codeRequest, fields: newFields })
                            }} />
                    }
                }
                return null
            })}
            <Stack>
                <Label>Attachments</Label>
                <Stack
                    className="feedback-drop-target"
                    styles={{
                        root: {
                            borderStyle: "dashed",
                            borderColor: "cornflowerblue",
                            borderWidth: "2px",
                            padding: "30px",
                            textAlign: "center",
                            cursor: "pointer",
                        },
                    }} >Click to Add or Drop Attachments Here</Stack>
            </Stack>
            <div style={{ display: "none" }}>
                <DropzoneComponent
                    config={{
                        postUrl: "no-url",
                        dropzoneSelector: ".feedback-drop-target",
                    }}
                    djsConfig={{
                        autoProcessQueue: false,
                        previewsContainer: false,
                        clickable: ".feedback-drop-target",
                    }}
                    eventHandlers={{
                        addedfile: _onDropzoneFileAdded,
                    }} />
            </div>
            <List items={attachments} onRenderCell={_onRenderAttachmentItem} />
            <TextField
                label="Comments"
                multiline
                value={codeRequest.comments}
                onChange={(e, newValue) => {
                    setCodeRequest({ ...codeRequest, comments: newValue || '' })
                }} />
            <Stack horizontal>
                {isLoading && <Spinner size={SpinnerSize.large} />}
                <PrimaryButton text="Save Request" onClick={onSubmit} disabled={!isDirty} />
            </Stack>
        </Stack>
    </Panel>
}
