import { createTheme } from '@fluentui/theme';

// Define your custom theme
export const VMRSTheme = createTheme({
  palette: {
    themePrimary: '#37368b',
    themeLighterAlt: '#f4f4fa',
    themeLighter: '#d6d6ed',
    themeLight: '#b5b4dd',
    themeTertiary: '#7776ba',
    themeSecondary: '#49479a',
    themeDarkAlt: '#33317e',
    themeDark: '#2b2a6b',
    themeDarker: '#1f1f4f',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#221f20',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
  // Add more customizations to the theme as needed
});
