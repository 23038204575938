import { IColumn } from "@fluentui/react";

export type FieldType = "string" | "number" | "boolean"

export interface AppColumn extends Omit<IColumn, "fieldName"> {
    fieldName: string;
    type: FieldType,
    readOnly?: boolean
}

interface BaseRecord {
    id: number
    createdBy: string
    createdDate: Date
    modifiedBy: string
    modifiedDate: Date
}

export interface Code extends BaseRecord {
    codeKeyId: number
    fields: Record<string, string | number | boolean>
}

export interface CodeKey extends BaseRecord {
    displayName: string
    descriptionAndPurpose: string
    use: string
    fields: string[]
    groups: string[]
    isObsolete: boolean
}

export interface CodeDetailsListItem extends Code {
    rowNumber: number
}

export interface CodeRequest extends BaseRecord {
    codeKeyId: number
    codeKey?: CodeKey
    fields: Record<string, string | number | boolean>
    codeId?: number
    codeObj?: Code
    status?: CodeRequestStatus
    comments?: string
}

export enum CodeRequestStatus {
    Pending = "Pending",
    Rejected = "Rejected",
    Approved = "Approved",
}

export interface IFeedbackFile {
    name: string;
    file: Dropzone.DropzoneFile;
}

export interface CodeRequestAttachment extends BaseRecord {
    codeRequestId: number;
    name: string;
}

export interface InstructionSet extends BaseRecord {
    title: string;
    example?: string;
    instructions: Instruction[];
}

export interface Instruction {
    title: string;
    description: string;
}

export type ActivityEntry = {
    Time: string
    Activity: string
    DisplayName: string
    LoginName: string
    SubscriptionLevel: string
    SubscriptionExpiration: string
}