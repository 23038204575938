import { MessageBar, MessageBarType, ProgressIndicator } from "@fluentui/react";
import { Outlet } from "react-router-dom";
import { login, useAuthStatus } from "../services";

export const RequireLogin = () => {
    const authStatus = useAuthStatus()

    if (!authStatus) {
        return <ProgressIndicator />
    }

    if (authStatus.isAuthenticated) {
        const expirationDate = authStatus.user?.subscription?.expiration
        if (authStatus.user?.isAdmin || (expirationDate && expirationDate >= new Date())) {
            return <Outlet />
        }
        else {
            return <MessageBar messageBarType={MessageBarType.blocked} >You do not have a valid subscription.</MessageBar>
        }
    }

    login()

    return null
}