import { ConstrainMode, DetailsListLayoutMode, DetailsList as FluentDetailsList, IColumn, IDetailsHeaderProps, IDetailsListProps, IGroup, SearchBox, SelectAllVisibility, SelectionMode, Stack, Sticky } from '@fluentui/react'
import { useEffect, useState } from 'react'

export type GetGroupNameFunction<T> = (column: keyof T, value: string) => string

export type GetColumnValueFunction<T> = (item: T, columnKey: keyof T) => string

export type FilteringProps<T> = {
    columns: (keyof T)[]
    /** A character that indicates any value should match. Default is '*' */
    wildcard?: string
}

export type GroupingProps<T> = {
    columns: SortColumn<T>[]
    getGroupName?: GetGroupNameFunction<T>
}

export type SortDirection = "ascending" | "descending"

export type SortColumn<T> = {
    column: keyof T
    direction: SortDirection
}

export type SortingProps<T> = {
    columns: SortColumn<T>[]
}

const PrettifyPattern: RegExp = /((?<=^|\s)[a-z])|((?<=[a-z])[A-Z])/g

export const prettify = (value: string): string => {
    return value.replace(PrettifyPattern, (m) => {
        const u = m.toUpperCase()
        return u === m ? " " + m : u
    })
}

const safeGetGroupName = <T,>(column: keyof T, value: string, onGetGroupName?: GetGroupNameFunction<T>): string => {
    let groupName: string = ''
    try {
        groupName = onGetGroupName!(column, value)
    }
    catch {
        groupName = `${prettify(String(column))}: ${value}`
    }
    return groupName
}

const safeGetColumnValue = <T,>(item: T, column: keyof T, onGetColumnValue?: GetColumnValueFunction<T>): string => {
    let value: string = ''
    try {
        value = onGetColumnValue!(item, column)
    }
    catch { /* GULP */ }
    if (!value) {
        try {
            const rawValue = item[column]
            if (typeof rawValue !== "undefined" && rawValue != null) {
                value = typeof rawValue === "string" ? rawValue : String(rawValue)
            }
        }
        catch { /* GULP */ }
    }
    return value || ''
}

export const sort = <T,>(a: T, b: T, sort: SortColumn<T>, onGetColumnValue?: GetColumnValueFunction<T>): -1 | 0 | 1 => {
    const aVal = a ? safeGetColumnValue(a, sort.column, onGetColumnValue) : ''
    const bVal = b ? safeGetColumnValue(b, sort.column, onGetColumnValue) : ''
    const aType = typeof aVal
    const bType = typeof bVal
    const pos = sort.direction === "ascending" ? 1 : -1
    const neg = sort.direction === "ascending" ? -1 : 1

    if (aType !== "undefined" && bType !== "undefined") {
        return aVal === bVal ? 0 : aVal > bVal ? pos : neg
    }
    if (bType !== "undefined") {
        return neg
    }
    if (aType !== "undefined") {
        return pos
    }
    return 0
}

export const applySorting = <T,>(items: T[], sortColumns: SortColumn<T>[], onGetColumnValue?: GetColumnValueFunction<T>): void => {
    items.sort((a: T, b: T) => {
        let sortResult = 0
        let index = 0;
        while (index < sortColumns.length && sortResult === 0) {
            sortResult = sort(a, b, sortColumns[index], onGetColumnValue)
            index++
        }
        return sortResult
    })
}

export const getLevelGroups = (groups: IGroup[], previousGroup: string): IGroup[] => {
    if (previousGroup) {
        const allGroups: IGroup[] = groups.slice()
        while (allGroups) {
            const group = allGroups.splice(0, 1)[0]
            if (group) {
                if (group.key === previousGroup) {
                    if (!group.children) {
                        group.children = []
                    }
                    return group.children
                }
                if (group.children) {
                    group.children.forEach(g => allGroups.push(g))
                }
            }
        }
    }
    return groups
}

export const getGroupItems = <T,>(items: T[], groupBy: SortColumn<T>[], onGetColumnValue?: GetColumnValueFunction<T>, onGetGroupName?: GetGroupNameFunction<T>): IGroup[] => {
    const groups: IGroup[] = []
    for (let index = 0; index < items.length; index++) {
        const item = items[index]
        let previousGroup = ''
        for (let level = 0; level < groupBy.length; level++) {
            const column = groupBy[level]
            const value = item ? safeGetColumnValue(item, column.column, onGetColumnValue) : ''
            const groupKey = (previousGroup ? previousGroup + "_" : "") + String(column.column) + "-" + value
            const levelGroups = getLevelGroups(groups, previousGroup)
            let group = levelGroups.find(lg => lg.key === groupKey)
            if (!group) {
                group = {
                    key: groupKey,
                    name: safeGetGroupName(column.column, value, onGetGroupName),
                    startIndex: index,
                    count: 0,
                    level: level
                }
                levelGroups.push(group)
            }
            group.count++
            previousGroup = groupKey
        }
    }
    return groups
}

export const buildPattern = (searchText: string, wildcard?: string): string => {
    if (!searchText) {
        return searchText
    }
    const finalWildcard = wildcard || '*'
    let newText = ''
    for (const c of searchText) {
        if (c === finalWildcard) {
            newText += ".{1}"
        }
        else {
            newText += c
        }
    }
    return `${newText}`
}

export const getFilteredItems = <T,>(items: T[], filters: [keyof T, string][], onGetColumnValue?: GetColumnValueFunction<T>): T[] => {
    let filteredItems = items.slice()
    if (filters.length > 0) {
        filteredItems = filteredItems.filter(item => {
            return typeof item === "object" && item !== null &&
                filters.every(([column, pattern]) => {
                    const fieldValue = safeGetColumnValue(item, column, onGetColumnValue)
                    const patterns = pattern.split(" ")
                    return patterns.every(p => new RegExp(p, "ig").test(fieldValue))
                })
        })
    }
    return filteredItems
}

export type DetailsListProps<T> = IDetailsListProps & {
    items: T[]
    filtering?: FilteringProps<T>
    grouping?: GroupingProps<T>
    sorting?: SortingProps<T>
    /** A function to get the value of a column. Used for filter and sort logic only. If unspecified, the "fieldName" prop of the column will be used.  */
    getColumnValue?: (item: T, column: keyof T) => string
}

export function DetailsList<T>(props: DetailsListProps<T>) {
    const { items: allItems, sorting, grouping, filtering, getColumnValue, onColumnHeaderClick, onRenderDetailsHeader, ...rest } = props

    const [filters, setFilters] = useState<[keyof T, string][]>([])
    const [groups, setGroups] = useState<IGroup[] | undefined>()
    const [items, setItems] = useState<T[]>([])

    const customStyles = {
        root: {
            borderBottom: '1px solid #221f20',  // Adding a border to the entire DetailsList
            selectors: {
                '.ms-DetailsRow-cell': {
                    borderRight: '1px solid #221f20',  // Add a bottom border to every row except the last one
                },
                '.ms-DetailsRow-cell:first-child': {
                    borderLeft: '1px solid #221f20',  // Add a bottom border to every row except the last one
                },
                '.ms-DetailsRow:not(:last-child) .ms-DetailsRow-cell': {
                    borderBottom: '1px solid #221f20',  // Add a bottom border to every row except the last one
                },
                '.ms-DetailsRow:first-child .ms-DetailsRow-cell': {
                    borderTop: '1px solid #221f20',  // Top border only for the first row
                },
            },
        },
    };
    
    const onColumnHeaderClickOverride = (event?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        if (onColumnHeaderClick) {
            try {
                onColumnHeaderClick(event, column)
            }
            catch (error) {
                console.log("SimpleList: An error occurred calling the onColumnHeaderClick")
            }
        }
        if (column && sorting?.columns?.some(c => c.column === column.key)) {
            if (column.isSorted) {
                if (!column.isSortedDescending) {
                    column.isSortedDescending = true
                }
                else {
                    column.isSorted = false
                    column.isSortedDescending = undefined
                }
            }
            else {
                column.isSorted = true
            }
        }
    }

    const onRenderDetailsHeaderOverride = (headerProps?: IDetailsHeaderProps, defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null): JSX.Element | null => {
        if (defaultRender && headerProps) {
            let headerContent: JSX.Element | null = null
            const showFilters = headerProps.columns.some(c => filtering?.columns?.some(fc => fc === c.key))
            if (showFilters) {
                headerContent = <Stack styles={{ root: { ".ms-DetailsHeader": { border: "none" } } }}>
                    {onRenderDetailsHeader ? onRenderDetailsHeader(headerProps, defaultRender) : defaultRender(headerProps)}
                    {defaultRender({
                        ...headerProps,
                        styles: {
                            root: {
                                padding: 0,
                                height: 32,
                                lineHeight: 32,
                                "[class*='cellIsGroupExpander']": {
                                    visibility: "collapse"
                                }
                            }
                        },
                        selectAllVisibility: props.selectionMode === SelectionMode.none ? SelectAllVisibility.none : SelectAllVisibility.hidden,
                        columns: headerProps.columns.map(c => {
                            return {
                                ...c,
                                styles: {
                                    root: {
                                        width: "100%",
                                        height: 32,
                                        lineHeight: 32,
                                        ":hover": {
                                            background: "unset !important"
                                        }
                                    },
                                    sortIcon: {
                                        display: "none !important"
                                    },
                                    cellName: {
                                        width: "100%"
                                    },
                                    cellTitle: {
                                        padding: 0
                                    }
                                },
                                onColumnClick: (ev) => ev.stopPropagation(),
                                onRenderHeader: () => filtering?.columns?.some(fc => fc === c.key)
                                    ? <SearchBox
                                        title={`You can search using the ${filtering.wildcard || "@"} symbol as a wildcard`}
                                        styles={{
                                            root: {
                                                border: 0,
                                                ":after": {
                                                    content: "none !important"
                                                }
                                            }
                                        }}
                                        onClear={() => {
                                            setFilters(filters.filter(([column]) => column !== c.key))
                                        }}
                                        onSearch={(newValue) => {
                                            const newFilters = filters.filter(([column]) => column !== c.key)
                                            if (newValue) {
                                                newFilters.push([c.key as keyof T, newValue])
                                            }
                                            setFilters(newFilters)
                                        }}
                                    />
                                    : null
                            }
                        })
                    })}
                </Stack>
            }
            else {
                headerContent = onRenderDetailsHeader ? onRenderDetailsHeader(headerProps, defaultRender) : defaultRender(headerProps)
            }
            return <Sticky>{headerContent}</Sticky>
        }
        return null
    }

    useEffect(() => {
        const sortColumns = sorting?.columns || []
        const groupColumns = grouping?.columns || []
        const wildcard = filtering?.wildcard || "@"

        const newItems = getFilteredItems(allItems, filters.map(([c, v]) => [c, buildPattern(v, wildcard)]), getColumnValue)
        let newGroups: IGroup[] | undefined = undefined
        const columns: SortColumn<T>[] = []
        if (groupColumns.length > 0) {
            groupColumns.forEach(c => columns.push(c))
        }
        if (sortColumns.length > 0) {
            sortColumns.forEach(c => {
                if (!columns.some(x => x.column === c.column)) {
                    columns.push(c)
                }
            })
        }
        if (columns.length > 0) {
            applySorting(newItems, columns, getColumnValue)
        }
        if (groupColumns.length > 0) {
            newGroups = getGroupItems(newItems, groupColumns, getColumnValue, grouping?.getGroupName)
        }
        setGroups(newGroups)
        setItems(newItems)
    }, [allItems, sorting?.columns, grouping?.columns, grouping?.getGroupName, filtering?.columns, filtering?.wildcard, filters, getColumnValue])

    return <FluentDetailsList
        {...rest}
        styles={customStyles}
        onColumnHeaderClick={onColumnHeaderClickOverride}
        onRenderDetailsHeader={onRenderDetailsHeaderOverride}
        items={items}
        groups={groups} />
}