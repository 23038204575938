import { MessageBar, MessageBarType, ProgressIndicator } from "@fluentui/react";
import { Link, Outlet } from "react-router-dom";
import { login, useAuthStatus } from "../services";

export const RequireAdmin = () => {
    const authStatus = useAuthStatus()

    if (!authStatus) {
        return <ProgressIndicator />
    }

    if (!authStatus.isAuthenticated) {
        login()

        return null
    }

    if (authStatus.user && authStatus.user.isAdmin) {
        return <Outlet />
    }

    return <MessageBar messageBarType={MessageBarType.blocked}>
        <span>Access Denied.</span>
        <Link className="ms-3" to="/">Home</Link>
    </MessageBar>
}