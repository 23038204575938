import { Stack, Text, SearchBox, IBaseButtonProps, DefaultButton, ISearchBoxProps, CommandBarButton, Icon, ThemeProvider } from '@fluentui/react'
import { useNavigate } from "react-router-dom";
import { VMRSTheme } from '.'; // Import your custom theme

export interface SearchOptions {
  value: string
  onChange: (value: string) => void
  placeholder?: string
}

interface ActionBarProps {
  searchOptions?: SearchOptions
  searchProps?: ISearchBoxProps
  action?: React.ReactNode
  actionRight?: React.ReactNode
}

interface PageLayoutProps extends ActionBarProps {
  children: React.ReactNode
  title: string
  parent?: {
    title: string,
    url: string
  }
}

interface RefreshButtonProps extends IBaseButtonProps {
  iconSize?: string
}

export function PageLayout(props: PageLayoutProps) {
  const { children, title, searchOptions, action, actionRight, searchProps, parent } = props
  const navigate = useNavigate()

  return (
    <ThemeProvider theme={VMRSTheme}>
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <CommandBarButton onClick={() => navigate("/")}>
            <Text variant="xxLarge">VMRS</Text>
          </CommandBarButton>
          <Icon iconName="ChevronRight" style={{ alignSelf: "center" }} />
          {parent && <>
            <CommandBarButton onClick={() => navigate(parent.url)}>
              <Text variant="xxLarge">{parent.title}</Text>
            </CommandBarButton>
            <Icon iconName="ChevronRight" style={{ alignSelf: "center" }} />
          </>}
          <Text variant="xxLarge">{title}</Text>
        </Stack>
        <ActionBar searchProps={searchProps} searchOptions={searchOptions} action={action} actionRight={actionRight} />
        {children}
      </Stack>
    </ThemeProvider>
  )
}

export function ActionBar(props: ActionBarProps) {
  const { searchOptions, action, actionRight, searchProps } = props

  return (
    <Stack horizontal horizontalAlign="space-between" verticalAlign="center" style={{ marginTop: "1rem" }} tokens={{ childrenGap: 10 }}>
      {searchOptions && (
        <SearchBox
          {...searchProps}
          disableAnimation
          placeholder={searchOptions.placeholder || 'Search'}
          value={searchOptions.value}
          onChange={(_event, value) => searchOptions.onChange(value || '')} />
      )}
      {action && (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {action}
        </Stack>
      )}
      {actionRight}
    </Stack>
  )
}

export function RefreshButton(props: RefreshButtonProps) {
  const { iconSize = '12px' } = props

  return (
    <DefaultButton iconProps={{ iconName: 'Refresh', styles: { root: { fontSize: iconSize } } }} {...props}>
      Refresh
    </DefaultButton>
  )
}