import { Dialog, CommandBar, CommandBarButton, ConstrainMode, DefaultButton, DetailsListLayoutMode, DatePicker, IColumn, ICommandBarItemProps, ICommandBarStyles, Icon, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, Text, TextField, ThemeProvider, Toggle, ColumnActionsMode, ProgressIndicator } from "@fluentui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as services from "../services";
import { allCodeKeyColumns, allColumns, dateFormatter, submitNewCode, updateCode, useAuthStatus } from "../services";
import { AppColumn, Code, CodeDetailsListItem, CodeKey } from "../types";
import { CodeRequestPanel } from ".";
import * as XLSX from "xlsx";
import { ErrorModal, VMRSTheme, DetailsList, DetailsListProps } from "../components";
import { DateRange, DateRangePicker } from "../components/DateRangePicker";

interface CodeFilters {
    dateRange: DateRange;
    searchText?: string;
    latestCode?: boolean;
}

function normalizeDate(date: string | Date) {
    const dateObject = new Date(date);
    return new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());
}

const getFilteredItems = <T extends CodeForUI,>(
    codes: T[],
    filters: CodeFilters,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined,
): T[] => {
    let filteredItems = [...codes];

    if (filters.searchText && filters.searchText.length > 0) {
        const normalizedSearchText = filters.searchText.toLocaleUpperCase();
        filteredItems = filteredItems.filter((item) => {
            try {
                if (item && item.fields) {
                    return Object.values(item.fields).some((value) => {
                        const normalizedFieldValue = String(value).toLocaleUpperCase();
                        return normalizedFieldValue.includes(normalizedSearchText);
                    });
                }
            } catch (err) {
                console.log("Search Text Error:", err);
            }
            return false;
        });
    }

    if (filters.latestCode) {
        const now = new Date();
        now.setDate(now.getDate() - 7);
        filteredItems = filteredItems.filter((item) => new Date(item.modifiedDate) > now);
    }

    if (filters.dateRange) {
        filteredItems = filteredItems.filter((item) => {
            // normalizing the dates to exclude the time and only compare down to the day
            const itemDate = normalizeDate(item[filters.dateRange.field]);
            const startDate = normalizeDate(filters.dateRange.startDate);
            const endDate = normalizeDate(filters.dateRange.endDate);
    
            return itemDate >= startDate && itemDate <= endDate;
        });
    }    
    
    if (sortColumn) {
        if (sortColumn === "createdDate" || sortColumn === "modifiedDate") {
            filteredItems.sort((a, b) => {
                const valueA = new Date(a?.[sortColumn]);
                const valueB = new Date(b?.[sortColumn]);
                return sortAscending ? valueA.getTime() - valueB.getTime() : valueB.getTime() - valueA.getTime();
            });
        } else {
            filteredItems.sort((a, b) => {
                const valueA = String(a?.fields?.[sortColumn] || "");
                const valueB = String(b?.fields?.[sortColumn] || "");
                return sortAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            });
        }
    }

    return filteredItems.map((c, i) => ({ ...c, rowNumber: i + 1 }));
};

const commandBarStyles: ICommandBarStyles = {
    root: {
        padding: "8px",
        marginTop: "1.5rem"
    },
};

function excelSerialDateToDate(serial: number) {
    const utcDays  = Math.floor(serial - 25569);
    const utcValue = utcDays * 86400; 
    return new Date(utcValue * 1000);
}

interface BulkAddOutcome {
    outcome: "success" | "error";
    message?: string;
}

export function CodeKeyDetails() {
    const { id: idString } = useParams();
    const id = idString ? Number(idString) : 0;
    const navigate = useNavigate();
    const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
    const [sortAscending, setSortAscending] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [codes, setCodes] = useState<CodeForUI[]>([]);
    const [codeKey, setCodeKey] = useState<CodeKey | undefined>();
    const [selectedCode, setSelectedCode] = useState<any>();
    const [columns, setColumns] = useState<AppColumn[]>([]);
    const [filters, setFilters] = useState<CodeFilters>({ dateRange: { startDate: new Date(1998, 0, 1), endDate: new Date(), field: "createdDate" }, searchText: "", latestCode: false });
    const [bulkAddOutcome, setBulkAddOutcome] = useState<BulkAddOutcome | undefined>();
    const [newCodeType, setNewCodeType] = useState<"code" | "codeRequest">();
    const authStatus = services.useAuthStatus();

    const refresh = useCallback(() => {
        setLoading(true);
        services
            .getCodeKey(id)
            .then(async (codeKey) => {
                const codeKeyCodes = codeKey ? (await services.getCodes(codeKey.id)) : [];
                setCodeKey(codeKey);
                // Add logic to conditionally hide the 'isObsolete' column
                let columnsToSet: AppColumn[] = []
                codeKey.fields.forEach(f => {
                    const column = allColumns.find(c => c.fieldName === f)
                    if (column) {
                        columnsToSet.push(column)
                    }
                })
                const obsoleteColumn = allColumns.find((c) => c.fieldName === "isObsolete");
                if (obsoleteColumn) {
                    obsoleteColumn.minWidth = 100;
                    obsoleteColumn.maxWidth = 100;
                    obsoleteColumn.isResizable = false;
                    columnsToSet = [...columnsToSet, obsoleteColumn];
                }
                columnsToSet.splice(0, 0, {
                    key: "rowNumber",
                    minWidth: 28,
                    name: "Row",
                    fieldName: "rowNumber",
                    type: "string",
                    isResizable: true,
                    columnActionsMode: ColumnActionsMode.disabled
                })
                allCodeKeyColumns.forEach(c => columnsToSet.push(c))
                setColumns(columnsToSet || []);
                setCodes((codeKeyCodes || []).map((c, i) => ({ ...c, rowNumber: i + 1 })));
            })
            .catch((err) => {
                console.error("Error while fetching code key:", err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    useEffect(() => refresh(), [refresh]);

    const toggles = (
        <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="center">
            <Toggle
                label="Show Latest Code"
                inlineLabel
                onText="Yes"
                offText="No"
                checked={filters.latestCode}
                onChange={(_, value) => setFilters({ ...filters, latestCode: value || false })}
            />
        </Stack>
    );

    const handleColumnClick = (column: IColumn) => {
        let newSortAscending = sortColumn === column.fieldName ? !sortAscending : true;
        setSortColumn(column.fieldName);
        setSortAscending(newSortAscending);
    };

    const exportToCSV = () => {
        const itemsToExport = getFilteredItems(codes, filters, sortColumn, sortAscending);
        const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(itemsToExport);
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${codeKey?.displayName}.csv`);
        document.body.appendChild(link);
        link.click();
    };

    const exportToExcel = (exportData: any[], fileName: string) => {
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Codes");
        const excelBuffer = XLSX.write(workbook, { type: "buffer" });
        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    const convertToXLSX = (data: Code[]) => {
        const sheetData: any[] = [];
        data.forEach(item => {
            const row: any = {};

            for (const c in columns) {
                const column = columns[c];
                if (column.fieldName === "rowNumber") row[column.name] = (item as CodeDetailsListItem).rowNumber
                else if (column.fieldName === "isObsolete") row[column.name] = item.fields[column.fieldName] ? "Yes" : "";
                else if (column.fieldName === "modifiedDate" || column.fieldName === "createdDate") {
                    row[column.name] = new Date(item[column.fieldName]).toISOString();
                } else {
                    row[column.name] = item.fields[column.fieldName as keyof Code] || "";
                }
            }

            sheetData.push(row);
        });
        return sheetData;
    };

    const formatDataFromExcel = (data: any[]) => {
        if (!authStatus?.user || !authStatus.user?.isAdmin) return console.error("User is not an admin")
        if (!codeKey?.id) return console.error("Code Key ID is undefined")

        const codes: Code[] = []
        data.forEach((item) => {
            const code: Code = {
                codeKeyId: codeKey.id,
                createdBy: "",
                createdDate: new Date(),
                modifiedBy: "",
                modifiedDate: new Date(),
                id: 0,
                fields: {}
            }
            columns.forEach((c) => {
                if (!item[c.name]) return
                if (c.fieldName === "modifiedDate" || c.fieldName === "createdDate") {
                    code[c.fieldName] = excelSerialDateToDate(item[c.name])
                } else if (c.fieldName === "isObsolete") {
                    code.fields[c.fieldName] = Boolean(item[c.name])
                } else {
                    code.fields[c.fieldName] = String(item[c.name])
                }
            });
            codes.push(code)
        });
        return codes
    }
    
    const downloadTemplate = () => exportToExcel(columns.filter(c => c.name !== "Row").map(c => ({ [c.name]: undefined })), `${codeKey?.displayName} - Template.xlsx`)

    const convertToCSV = (data: Code[]) => {
        const columnNames = columns.map((column) => column.name);
        const csvRows = [columnNames.join(",")];

        data.forEach((item) => {
            const values = columns.map((column) => item.fields[column.fieldName] || item[column.fieldName as keyof Code] || "");
            const row = values.map((value) => (value ? String(value) : "")).join(",");
            csvRows.push(row);
        });

        return csvRows.join("\n");
    };

    const handleExcelExport = () => {
        const itemsToExport = getFilteredItems(codes, filters, sortColumn, sortAscending);
        const exportData = convertToXLSX(itemsToExport);
        exportToExcel(exportData, `${codeKey?.displayName}.xlsx`);
    }

    const handleExcelTemplateUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files || files.length === 0) return console.error("No files found");
    
        const file = files[0];
        const reader = new FileReader();
    
        reader.onload = (e) => {
            const data = e.target?.result;
            if (!data) return console.error("No data found");
    
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet);
    
            const codes = formatDataFromExcel(sheetData);
    
            if (codes && codeKey?.id) {
                services.submitBulkCodes(codeKey.id, codes)
                    .then(() => {
                        refresh()
                        setBulkAddOutcome({ outcome: "success" })
                    })
                    .catch((err) => {
                        console.error(err);
                        setBulkAddOutcome({ outcome: "error", message: err.error || err.message || "Unknown Error" })
                    });
            }
        };
    
        reader.readAsBinaryString(file);
        e.target.value = ''
    };    

    const exportButtonItems: ICommandBarItemProps[] = [
        {
            key: "export",
            text: "Export",
            iconProps: { iconName: "Export" },
            subMenuProps: {
                items: [
                    {
                        key: "exportExcel",
                        text: "Export to Excel",
                        onClick: handleExcelExport
                    },
                    {
                        key: "exportCSV",
                        text: "Export to CSV",
                        onClick: exportToCSV,
                    },
                ],
            },
        },
    ];

    return (
        <>
            {loading && <ProgressIndicator label="Loading..." />}
            {codeKey && (
                <ThemeProvider theme={VMRSTheme}>
                    <Stack tokens={{ childrenGap: 20 }}>
                        {/* Title and Navigation */}
                        <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="baseline">
                            {/* Title */}
                            <CommandBarButton onClick={() => navigate("/")}>
                                <Text variant="xxLarge">VMRS</Text>
                            </CommandBarButton>
                            <Icon iconName="ChevronRight" />
                            <CommandBarButton onClick={() => navigate("/CodeKeys")}>
                                <Text variant="xxLarge">Code Keys</Text>
                            </CommandBarButton>
                            <Icon iconName="ChevronRight" />
                            <Text variant="xxLarge">{codeKey.displayName}</Text>
                        </Stack>

                        {/* Description and Purpose */}
                        <Pivot linkSize="large">
                            <PivotItem headerText="Codes">
                                {/* Command Bar */}
                                <CommandBar
                                    styles={commandBarStyles}
                                    items={[
                                        {
                                            key: "search",
                                            onRender: () => (
                                                <Stack tokens={{ childrenGap: 8 }} verticalAlign="center">
                                                    <TextField
                                                        placeholder="Search Codes"
                                                        value={filters.searchText || ""}
                                                        onChange={(e, value) =>
                                                            setFilters({ ...filters, searchText: value || "" })
                                                        }
                                                    />
                                                </Stack>
                                            ),
                                        },
                                        {
                                            key: "dateRange",
                                            onRender: () => (
                                                <Stack tokens={{ childrenGap: 8 }} styles={{ root: { marginBottom: "1.5rem", marginLeft: "0.5rem" } }} verticalAlign="center">
                                                    <DateRangePicker
                                                        selectedDate={filters.dateRange}
                                                        handleDateChange={(startDate, endDate, field) => setFilters(prev => ({ ...prev, dateRange: { startDate, endDate, field } }))}
                                                    />
                                                </Stack>
                                            ),
                                        }
                                    ]}
                                    farItems={[
                                        {
                                            key: "action",
                                            text: authStatus?.user?.isAdmin ? "Add New Code" : "Create New Code Request",
                                            iconProps: { iconName: "Add" },
                                            onClick: () =>
                                                setNewCodeType(authStatus?.user?.isAdmin ? "code" : "codeRequest"),
                                        },
                                        ...(authStatus?.user?.isAdmin ? [{
                                            key: "bulk",
                                            text: "Add Bulk Codes",
                                            iconProps: { iconName: "Boards" },
                                            subMenuProps: {
                                                items: [
                                                    {
                                                        key: "download",
                                                        text: "Download Template",
                                                        onClick: downloadTemplate,
                                                    },
                                                    {
                                                        key: "upload",
                                                        text: "Upload Template",
                                                        onClick: () => {
                                                            const fileInput = document.getElementById("templateFileInput") as HTMLInputElement;
                                                            if (fileInput) fileInput.click()
                                                        },
                                                    },
                                                ],                                    
                                            }
                                        }] : []),
                                        ...exportButtonItems,
                                        {
                                            key: "toggles",
                                            onRender: () => toggles,
                                        },
                                    ]}
                                />

                                {/* Codes List */}
                                <CodesList
                                    key={codeKey.id}
                                    codes={codes}
                                    filtering={{
                                        columns: (codeKey.fields as (keyof CodeForUI)[]).concat(["modifiedDate", "createdDate"])
                                    }}
                                    getColumnValue={(item: CodeForUI, columnKey: keyof CodeForUI) => {
                                        if (columnKey === "modifiedDate" || columnKey === "createdDate") {
                                            const value = item[columnKey]
                                            return value ? dateFormatter.format(new Date(value)) : ''
                                        }
                                        return item.fields[columnKey] as string
                                    }}
                                    grouping={{
                                        columns: (codeKey.groups || []).map(g => ({
                                            column: g as keyof CodeForUI,
                                            direction: "ascending"
                                        }))
                                    }}
                                    filters={filters}
                                    sortColumn={sortColumn}
                                    sortAscending={sortAscending}
                                    columns={columns.map((column) => ({
                                        ...column,
                                        isSorted: column.fieldName === sortColumn,
                                        isSortedDescending: !sortAscending,
                                        onColumnClick: () => handleColumnClick(column),
                                    }))}
                                    compact
                                    flexMargin={20}
                                    constrainMode={ConstrainMode.horizontalConstrained}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    onRenderRow={(props?: { item: any }, defaultRenderer?: any) => (
                                        <div
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setSelectedCode(props?.item);
                                            }}>
                                            {defaultRenderer!(props)}
                                        </div>
                                    )}
                                    selectionMode={SelectionMode.none}
                                />
                            </PivotItem>

                            {/* Use */}
                            <PivotItem headerText="Description and Purpose / Use">
                                <Stack tokens={{ childrenGap: 8, padding: 8 }}>
                                    {codeKey.descriptionAndPurpose && (
                                        <Stack.Item>
                                            <Text variant="mediumPlus">
                                                <span style={{ whiteSpace: 'pre-line' }}>
                                                    {codeKey.descriptionAndPurpose}
                                                </span>
                                            </Text>
                                        </Stack.Item>
                                    )}
                                    {codeKey.use && (
                                        <Stack.Item>
                                            <Text variant="mediumPlus">
                                                <span style={{ whiteSpace: 'pre-line' }}>
                                                    {codeKey.use}
                                                </span>
                                            </Text>
                                        </Stack.Item>
                                    )}
                                </Stack>
                            </PivotItem>
                        </Pivot>

                        {/* Code Panel */}
                        {(newCodeType === "code" || selectedCode) && (
                            <CodePanel
                                code={selectedCode}
                                allCodes={codes}
                                setAllCodes={setCodes}
                                codeKey={codeKey}
                                onDismiss={() => {
                                    setNewCodeType(undefined);
                                    setSelectedCode(undefined);
                                    refresh();
                                }}
                            />
                        )}

                        {/* Code Request Panel */}
                        {newCodeType === "codeRequest" && (
                            <CodeRequestPanel codeKey={codeKey} dismissPanel={() => setNewCodeType(undefined)} />
                        )}

                        {/* Bulk Add Success/Error Dialog */}
                        {bulkAddOutcome && (
                            <Dialog
                                hidden={false}
                                onDismiss={() => setBulkAddOutcome(undefined)}
                                dialogContentProps={{
                                    title: "Add Bulk Codes: " + (bulkAddOutcome.outcome === "success" ? "Success" : "Error"),
                                    subText: bulkAddOutcome.outcome === "success" ? "Codes added successfully." : "There was an error adding the codes: " + bulkAddOutcome.message,
                                }}
                                modalProps={{
                                    isBlocking: true,
                                    styles: { main: { maxWidth: 450 } },
                                }}>
                                <DefaultButton text="Dismiss" onClick={() => setBulkAddOutcome(undefined)} />
                            </Dialog>
                        )}

                        <input type="file" id="templateFileInput" style={{ display: 'none' }} onChange={handleExcelTemplateUpload} accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />
                    </Stack>
                </ThemeProvider>
            )}
        </>
    );
}

type CodeForUI = Code & {
    rowNumber: number
}

interface CodesListProps<T extends CodeForUI> extends Omit<DetailsListProps<T>, "items"> {
    codes: T[];
    filters: CodeFilters;
    sortColumn?: string;
    sortAscending?: boolean;
}

function CodesList<T extends CodeForUI>(props: CodesListProps<T>) {
    const { codes, filters, sortColumn, sortAscending, ...rest } = props;
    const [items, setItems] = useState<T[]>([]);
    const allItems = useRef<T[]>([]);

    useEffect(() => {
        allItems.current = codes;
        setItems(getFilteredItems(codes, filters, sortColumn, sortAscending));
    }, [codes, filters, sortColumn, sortAscending]);

    return <DetailsList items={items} {...rest} />;
}

function getCode(code?: Partial<Code>): Code {
    const now = new Date();
    return {
        codeKeyId: code?.codeKeyId || 0,
        createdBy: code?.createdBy || "",
        createdDate: new Date(code?.createdDate || now),
        modifiedBy: code?.modifiedBy || "",
        modifiedDate: new Date(now), // always defaulting to now for new or edited codes because if it is saved or submitted it will always be the current date
        id: code?.id || 0,
        fields: code?.fields || {},
    };
}

interface CodePanelProps {
    code?: Code;
    codeKey: CodeKey;
    allCodes: CodeForUI[];
    setAllCodes: React.Dispatch<React.SetStateAction<CodeForUI[]>>;
    onDismiss: (ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) => void;
}

type ObjectWithStringValues = { [field: string]: string }

function CodePanel(props: CodePanelProps) {
    const { code: originalCode, codeKey, onDismiss, setAllCodes } = props;
    const isEditMode = !!originalCode;

    const fields = [...codeKey.fields, "isObsolete"]
        .map((prop) => allColumns.find((c) => c.fieldName === prop))
        .concat(allCodeKeyColumns)
        .filter((f) => f) as AppColumn[];

    const [code, setCode] = useState<Code>(getCode(originalCode));
    const [isNewOpen, setIsNewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<(Error | undefined)[]>([]);
    const [textFieldErrors, setTextFieldErrors] = useState<ObjectWithStringValues>({})
    const [textFieldRawInputs, setTextFieldRawInputs] = useState<ObjectWithStringValues>({})
    const [showConfirmation, setShowConfirmation] = useState(false)
    const authStatus = useAuthStatus();

    const onSubmit = () => {
        const updating = originalCode && originalCode.id > 0
        setIsLoading(true);
        (updating ? updateCode(code) : submitNewCode(codeKey.id, code))
            .then((newCode: Code) => {
                setAllCodes((prevCodes) => {
                    const newCodes = [...prevCodes];
                    const index = newCodes.findIndex((c) => c.id === newCode.id);
                    if (index >= 0) {
                        const originalCodeRowNumber = newCodes[index].rowNumber
                        // have to set as unknown as string because the fields are stored as a string in the database but the Code type expects the parsed object
                        newCodes[index] = { ...newCode, rowNumber: originalCodeRowNumber, fields: JSON.parse(newCode.fields as unknown as string) };
                    } else {
                        newCodes.push({ ...newCode, rowNumber: newCodes.length + 1, fields: JSON.parse(newCode.fields as unknown as string) });
                    }
                    return newCodes;
                })
                onDismiss()
            })
            .catch((err) => {
                setIsLoading(false);
                setErrors((prevErrors) => [...prevErrors, err]);
            });
    };

    const onDelete = () => {
        setIsLoading(true);
        services
            .deleteCode(code)
            .then((deletedCode: Code) => {
                setAllCodes((prevCodes) => {
                    const newCodes = [...prevCodes];
                    const index = newCodes.findIndex((c) => c.id === deletedCode.id);
                    if (index >= 0) {
                        newCodes.splice(index, 1);
                    }
                    return newCodes;
                });
                onDismiss();
            })
            .catch((err) => {
                setIsLoading(false);
                setErrors((prevErrors) => [...prevErrors, err]);
            });
    };

    useEffect(() => {
        const initializeRawInputs = () => {
            const newInputs = {} as ObjectWithStringValues
            fields.forEach(f => {
                newInputs[f.fieldName] = getFieldValue(code, f)
            })
            setTextFieldRawInputs(newInputs)
        }
        initializeRawInputs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // explicitly only running this on mount

    const isSaveDisabled = isLoading || Object.values(textFieldErrors).some(e => e.length > 0)

    return (
        <Panel
            headerText={authStatus?.user?.isAdmin ? (isEditMode ? "Edit Code" : "New Code") : "Code"}
            isOpen={true}
            onDismiss={onDismiss}
            closeButtonAriaLabel="Close"
            type={PanelType.medium}
        >
            {errors.length > 0 && <ErrorModal isModalOpen={true} dismissErrors={() => setErrors([])} errors={errors} />}
            <Stack tokens={{ childrenGap: 8 }}>
                {fields.length > 0 &&
                    fields.map((field, i) => {
                        switch (field.type) {
                            case "boolean": {
                                const isChecked = code.fields[field.fieldName] as boolean;
                                return (
                                    <Toggle
                                        key={`${field}${i}`}
                                        label={field.name}
                                        defaultChecked={isChecked}
                                        disabled={!authStatus?.user?.isAdmin}
                                        onChange={(_, checked) => {
                                            const newFields = { ...code.fields };
                                            newFields[field.fieldName] = true === checked;
                                            setCode({ ...code, fields: newFields });
                                        }}
                                    />
                                );
                            }
                            case "string": {
                                if (field.fieldName === "createdDate" || field.fieldName === "modifiedDate") {
                                    return (
                                        <DatePicker
                                            key={`${field}${i}`}
                                            label={field.name}
                                            disabled={getIsDisabled(field, authStatus?.user?.isAdmin)}
                                            value={code[field.fieldName] as Date}
                                            onSelectDate={(date) => {
                                                if (!date) return;

                                                const newCode = { ...code };
                                                date.setHours(12, 0, 0, 0); // date picker doesn't allow for time selection so this sets a safe default time

                                                // typescript isn't narrowing the type of the field name so I have to cast it
                                                newCode[field.fieldName as "createdDate" | "modifiedDate"] = date
                                                setCode(newCode);
                                            }}
                                        />
                                    )
                                }
                                return (
                                    <PanelTextField
                                        key={`${field}${i}`}
                                        field={field}
                                        code={code}
                                        codeKey={codeKey}
                                        allCodes={props.allCodes}
                                        disabled={getIsDisabled(field, authStatus?.user?.isAdmin)}
                                        error={textFieldErrors[field.fieldName] || ""}
                                        rawInput={textFieldRawInputs[field.fieldName] || ""}
                                        setRawInput={(input: string, fieldName?: string) => {
                                            setTextFieldRawInputs(prev => ({ ...prev, [fieldName || field.fieldName]: input }))
                                        }}
                                        setError={(error: string, fieldName?: string) => {
                                            setTextFieldErrors(prev => ({ ...prev, [fieldName || field.fieldName]: error }))
                                        }}
                                        setCode={setCode}
                                    />
                                );
                            }
                        }
                        return null;
                    })}
                {isLoading && <Spinner size={SpinnerSize.large} />}
                <Stack tokens={{ childrenGap: 8 }} horizontal>
                    {authStatus?.user?.isAdmin ? (
                        <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: "100%" } }}>
                            <PrimaryButton disabled={isSaveDisabled} text="Save Code" onClick={onSubmit} />
                            {isEditMode && <DefaultButton text="Delete Code" onClick={() => setShowConfirmation(true)} />}
                        </Stack>
                    ) : (
                        <DefaultButton disabled={isSaveDisabled} text="Request an Edit" onClick={() => setIsNewOpen(true)} />
                    )}
                </Stack>
            </Stack>
            {isNewOpen && <CodeRequestPanel code={code} dismissPanel={() => setIsNewOpen(false)} />}
            {showConfirmation && (
                <Dialog
                    hidden={false}
                    onDismiss={() => setShowConfirmation(false)}
                    dialogContentProps={{
                        title: "Delete Code",
                        subText: "Are you sure you want to delete this code?",
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } },
                    }}>
                    <Stack horizontal horizontalAlign="space-between">
                        <DefaultButton text="Cancel" onClick={() => setShowConfirmation(false)} />
                        <PrimaryButton text="Delete" onClick={onDelete} />
                    </Stack>
                </Dialog>
            )}
        </Panel>
    );
}

function getIsDisabled(field: AppColumn, isAdmin?: boolean) {
    if (!isAdmin) return true

    switch (field.fieldName) {
        case "createdDate":
            return true;
        case "VMRSCode":
            return true
        default:
            return false;
    }
}

function updateFieldValue(code: Code, field: AppColumn, newValue: string) {
    const newFields = { ...code.fields }
    newFields[field.fieldName] = newValue || ""
    code.fields = newFields
}

function getFieldValue(code: Code, field: AppColumn) {
    switch (field.fieldName) {
        case "createdDate":
            return code.createdDate ? dateFormatter.format(new Date(code.createdDate)) : "";
        case "modifiedDate":
            return code.modifiedDate ? dateFormatter.format(new Date(code.modifiedDate)) : "";
        default:
            return code.fields[field.fieldName] as string;
    }
}

interface ValidatorArgs {
    field: AppColumn
    code: Code
    allCodes: CodeForUI[]
    newValue: string
    setError: (error: string, fieldName?: string) => void
    setRawInput: (input: string, fieldName?: string) => void
}

interface InputValidators {
    [field: string]: ((args: ValidatorArgs) => void) | undefined
}

function validateModifiedDate(args: ValidatorArgs) {
    const { field, code, newValue: date, setError } = args

    try {
        const newDate = date ? new Date(date.replace("at", "")) : undefined;
        if (!newDate || isNaN(newDate.getTime())) throw new Error("Invalid Date");
        setError("");
        code.modifiedDate = newDate;
    } catch (err) {
        setError(field.fieldName);
        console.error(err);
    }
}

function validateDuplicateField(args: ValidatorArgs) {
    const { field, code, allCodes, newValue, setError } = args

    const isDuplicateCode = allCodes.some(c => c.fields?.[field.fieldName] === newValue && c.id !== code.id)

    if (isDuplicateCode) {
        // passing in the field name as the error and as the field name so that we have the option to set an error on a different field than the one we are currently typing in
        setError(field.fieldName, field.fieldName)
        console.error("Duplicate Field")
    } else {
        setError("", field.fieldName)
    }

    updateFieldValue(code, field, newValue)
}

// when adding autopopulators in the future just be sure to correctly manage setting the autopopulated field and error along with the field that is being currently edited
function autopopulateVMRSCodeField(args: ValidatorArgs) {
    const { code, field, newValue, setError, setRawInput } = args
    const newVMRSField = { fieldName: "VMRSCode" } as AppColumn

    // for some reason typescript isn't infering the type propertly so I define it explicitly
    const pieces: { [property: string]: string } = {
        codeSys: code.fields["codeSys"] as string || "",
        codeAsy: code.fields["codeAsy"] as string || "",
        comp: code.fields["comp"] as string || ""
    }

    pieces[field.fieldName] = newValue

    if (Object.values(pieces).some(p => !p.match(/^[0-9]{3}$/))) {
        updateFieldValue(code, field, newValue)
        updateFieldValue(code, newVMRSField, "")
        setRawInput("", "VMRSCode")
        setError("", "VMRSCode")
        return
    }

    const newCode = `${pieces.codeSys}-${pieces.codeAsy}-${pieces.comp}`

    updateFieldValue(code, field, newValue)
    validateDuplicateField({ ...args, field: newVMRSField, newValue: newCode })
    setRawInput(newCode, "VMRSCode")
}

type ErrorConditions = [boolean, string][];

function getFieldErrorMessage(conditions: ErrorConditions) {
    for (const [condition, message] of conditions) {
        if (condition) return message;
    }
    return undefined;
}

const getErrorConditions = (error: string): ErrorConditions => [
    [error === "modifiedDate", "Invalid Date"],
    [error === "code" || error === "VMRSCode", "This code already exists, please enter a unique code"]
]

const getInputValidators = (codeKeyId: number): InputValidators => ({
    modifiedDate: validateModifiedDate,
    code: codeKeyId === 34 ? validateDuplicateField : undefined,
    VMRSCode: codeKeyId === 33 ? validateDuplicateField : undefined
})

const getAutopopulators = (codeKeyId: number): InputValidators => ({
    codeSys: codeKeyId === 33 ? autopopulateVMRSCodeField : undefined,
    codeAsy: codeKeyId === 33 ? autopopulateVMRSCodeField : undefined,
    comp: codeKeyId === 33 ? autopopulateVMRSCodeField : undefined,
})

const getInputManipulators = (codeKeyId: number) => [getInputValidators(codeKeyId), getAutopopulators(codeKeyId)]

interface PanelTextFieldProps {
    field: AppColumn;
    code: Code;
    codeKey: CodeKey;
    allCodes: CodeForUI[];
    disabled: boolean
    error: string
    rawInput: string
    setRawInput: (input: string, fieldName?: string) => void
    setError: (error: string, fieldName?: string) => void
    setCode: (code: Code) => void;
}

// I'm using rawInput to allow the user to enter invalid values and then correct them without having to retype the entire value while maintaining the correct state internally for the code object
function PanelTextField(props: PanelTextFieldProps) {
    const { disabled, field, code, codeKey, allCodes, setCode, error, setError, rawInput, setRawInput } = props

    const errorMessage = getFieldErrorMessage(getErrorConditions(error))
    const [inputValidators, autopopulators] = getInputManipulators(codeKey.id)
    
    return (
        <TextField
            id={field.fieldName}
            label={field.name}
            errorMessage={error === field.fieldName ? errorMessage : ""}
            value={rawInput}
            disabled={disabled}
            onChange={(_e, fieldEdit = "") => {
                let updatedCode = { ...code }
                const inputValidator = inputValidators[field.fieldName]
                const autopopulator = autopopulators[field.fieldName]

                if (!inputValidator && !autopopulator) updateFieldValue(updatedCode, field, fieldEdit)
                const args = { field, code: updatedCode, allCodes, newValue: fieldEdit, setError, setRawInput }

                if (inputValidator) inputValidator(args)
                if (autopopulator) autopopulator(args)

                setRawInput(fieldEdit)
                setCode(updatedCode)
            }}
        />
    )
}