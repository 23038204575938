import { Stack, Text, Link, Image } from '@fluentui/react';
import { Outlet } from 'react-router-dom';
import { TopNav } from '.';

export function Layout() {
    return <>
        <div className="app">
            <TopNav />
            <main>
                <Outlet />
            </main>
        </div>
        <footer>
            <Stack horizontalAlign="center" tokens={{ padding: "20px" }} >
                <Stack horizontal horizontalAlign="space-evenly" tokens={{ childrenGap: 20 }} >
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Image className="logo-footer" src={"/images/logo_ata.png"} alt="VMRS Logo" />
                    </Stack>
                    <Stack horizontal wrap tokens={{ childrenGap: 40 }} style={{ flex: 1 }} >
                        <Stack className="footer-row-style">
                            <Link target="_blank" href="mailto:tmc@trucking.org">Contact Us</Link>
                            <Link data-interception="off" target="_blank" href="https://www.atabusinesssolutions.com">Shop ATA</Link>
                            <Link data-interception="off" target="_blank" href="https://www.trucking.org/Privacy_Policy.aspx">Privacy Policy</Link>
                            <Link data-interception="off" target="_blank" href="https://www.trucking.org/terms-use">Terms of Use</Link>
                        </Stack>
                        <Stack className="footer-row-style">
                            <Link data-interception="off" target="_blank" href="https://www.facebook.com/TechnologyMaintenanceCouncil/">Facebook</Link>
                            <Link data-interception="off" target="_blank" href="https://www.twitter.com/tmc4trucks">Twitter</Link>
                            <Link data-interception="off" target="_blank" href="https://www.youtube.com/c/TechnologyMaintenanceCouncil">YouTube</Link>
                            <Link data-interception="off" target="_blank" href="https://www.linkedin.com/company/truckingtmc">LinkedIn</Link>
                        </Stack>
                        <Stack>
                            <Text variant="mediumPlus">ATA Headquarters</Text>
                            <Text>80 M Street SE Suite 800</Text>
                            <Text>Washington, DC 20023</Text>
                        </Stack>
                        <Stack>
                            <Text variant="mediumPlus">ATA Capitol Hill Office</Text>
                            <Text>430 First Street, SE, Suite 100</Text>
                            <Text>Washington, DC 20003</Text>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack horizontalAlign="center">
                    <Text variant="small">&copy; {new Date().getFullYear()} - AMERICAN TRUCKING ASSOCIATIONS</Text>
                </Stack>
            </Stack>
        </footer>
    </>
}