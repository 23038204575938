import {
    CommandBar,
    IButtonStyles,
    ICommandBarItemProps,
    IContextualMenuItem,
    IImageProps,
    IPersonaStyleProps,
    IPersonaStyles,
    IStyleFunctionOrObject,
    Image,
    Link,
    Persona,
    PersonaSize,
    Stack,
    Text
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout, useAuthStatus } from "../services";

const primaryColor = "#37368b";
const primaryColorDark = "#2C2B6F";
const primaryColorLight = "#5757AB";
const secondaryColorDark = "#221f20";
const highlightBackground = "rgba(243, 242, 241, 0.25)"

const personaStyles: IStyleFunctionOrObject<IPersonaStyleProps, IPersonaStyles> = {
    root: {
        margin: "auto 8px",
        color: primaryColor,
    },
    details: {
        padding: 0,
        margin: "0 0 0 8px"
    },
    primaryText: {
        color: "#FFF",
        "&:hover": {
            color: "#FFF"
        }
    },
    secondaryText: {
        color: "#ffed3f"
    },
    
};

const topBarStyles: IButtonStyles = {
    root: {
        color: "white",
        backgroundColor: "transparent",
        "&.is-expanded": {
            backgroundColor: highlightBackground + " !important"
        },
        "& .ms-Icon": {
            backgroundColor: "transparent !important",
        }
    },
    rootHovered: {
        backgroundColor: highlightBackground,
    },
    rootPressed: {
        backgroundColor: highlightBackground
    },
    icon: {
        color: primaryColor,
    },
    iconExpandedHovered: {
        backgroundColor: highlightBackground + " !important"
    }
};

const navItemStyles: IButtonStyles = {
    root: {
        color: primaryColor,
        backgroundColor: "transparent",
    },
    icon: {
        color: secondaryColorDark,
    },
};

const navItems: ICommandBarItemProps[] = [
    {
        key: "ContactUs",
        text: "Contact Us",
        href: "mailto:VMRS@trucking.org?subject=VMRS%20Question",
        buttonStyles: navItemStyles,
    }
];

const imageProps: Partial<IImageProps> = {
    src: '/images/VMRSLOGO_v106.png'
};

export function TopNav() {
    const authStatus = useAuthStatus();
    const navigate = useNavigate();

    const [items, setItems] = useState<ICommandBarItemProps[]>([]);
    const [farItems, setFarItems] = useState<ICommandBarItemProps[]>([]);
    const [hideSubscriptionNotice] = useState(
        document.cookie.includes("HideSubscriptionNotice=true")
    );

    const getNavigateItems = useCallback(
        (items: ICommandBarItemProps[]): ICommandBarItemProps[] => {
            return items.map((x) => {
                const { href, data, ...rest } = x;
                const item = { ...rest };
                if (href) {
                    item.onClick = () => {
                        if (data === "navigate") {
                            navigate(href);
                        } else {
                            window.open(href);
                        }
                    };
                }
                return item;
            });
        },
        [navigate]
    );

    useEffect(() => {
        setItems(getNavigateItems(navItems));
    }, [setItems, getNavigateItems]);

    useEffect(() => {
        const newFarItems = [];
        if (authStatus?.user?.subscription && !hideSubscriptionNotice) {
            const subscriptionInfo: ICommandBarItemProps = {
                key: "subscriptionInfo",
                commandBarButtonAs: () => {
                    return <Text style={{ textAlign: "center", color: "#ffed3f" }} variant="medium">
                        {`VMRS ${authStatus.user!.subscription!.level}`}
                        <span><br />
                            Valid Thru{" "}
                            {authStatus.user!.subscription!.expiration.toLocaleDateString()}
                        </span>
                    </Text>
                }
            }
            newFarItems.push(subscriptionInfo)
        }
        if (authStatus && authStatus.user) {
            const menuItems: IContextualMenuItem[] = [
                {
                    key: "SignOut",
                    text: "Sign Out",
                    iconProps: { iconName: "SignOut" },
                    onClick: () => logout(),
                },
            ]
            if (authStatus.user && authStatus.user.isAdmin) {
                menuItems.splice(0, 0, {
                    key: "Activity Logs",
                    text: "Activity Logs",
                    iconProps: { iconName: "FullHistory" },
                    onClick: () => navigate("/Activity"),
                })
            }
            const displayName = authStatus.user.displayName;
            const userMenu: ICommandBarItemProps = {
                key: "Menu",
                buttonStyles: topBarStyles,
                onRenderIcon: () => (
                    <Persona
                        styles={personaStyles}
                        size={PersonaSize.size24}
                        text={displayName}
                        secondaryText={authStatus.user?.isAdmin ? "Administrator" : undefined}
                        showSecondaryText
                        initialsColor={primaryColorLight}
                    />
                ),
                subMenuProps: {
                    items: menuItems,
                },
            };
            newFarItems.push(userMenu);
        }
        setFarItems(getNavigateItems(newFarItems));
    }, [authStatus, authStatus?.user, getNavigateItems, hideSubscriptionNotice, navigate]);
    return (
        <header>
            <Stack>
                <Stack horizontal verticalAlign="center" styles={{
                    root: {
                        padding: "8px",
                        backgroundColor: primaryColor,
                        height: 98,
                    },
                }}>
                    <Stack.Item grow>
                        <Link href="/"><Image {...imageProps} height={72}></Image></Link>
                    </Stack.Item>
                    <CommandBar
                        styles={{
                            root: {
                                backgroundColor: primaryColor,
                                padding: "0 0 0 8px"
                            },
                        }}
                        items={[]}
                        farItems={farItems}
                    />
                </Stack>
                <Stack styles={{
                    root: {
                        backgroundColor: "white",
                        padding: 8
                    }
                }}>
                    <Stack horizontal verticalAlign="center">
                        <Image src='/images/TMC_187_ata_0.png' height={32} />
                        <Stack.Item grow>
                            <Text styles={{ root: { color: secondaryColorDark, paddingLeft: "8px", fontStyle: "italic" } }}>Provided by ATA's Technology & Maintenance Council (TMC)</Text>
                        </Stack.Item>
                        <CommandBar
                            styles={{
                                root: {
                                    backgroundColor: "white",
                                    color: primaryColorDark,
                                    height: 40
                                },
                            }}
                            items={[]}
                            farItems={items}
                        />
                    </Stack>
                </Stack>
                <Stack styles={{
                    root: {
                        borderTop: "1px solid",
                        borderTopColor: primaryColorLight,
                        borderBottom: "1px solid",
                        borderBottomColor: primaryColorLight,
                        backgroundColor: "white",
                        textAlign: "center",
                        padding: "10px"
                    },
                }}>
                    <Text variant="medium">The Vehicle Maintenance Reporting Standards (VMRS) Services Portal serves as the official repository for all things concerning VMRS.
                        <br />We hope you will find the portal an exciting new tool for implementing VMRS and we thank you for licensing VMRS.</Text>
                </Stack>
            </Stack>
        </header>
    );
}
